import { Box, Button, Container, CssBaseline, Grid, Paper, Typography } from '@mui/material'
import TextField from '@mui/material/TextField';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import apiClient from '../api/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../redux/CounterSlice';
import { Toast } from 'primereact/toast';
import CircularProgress from '@mui/material/CircularProgress';
const Login = () => {
  let initialstate={
    email:"",
    password:""
  }
  
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const[logindata,setLoginData]=useState(initialstate)
  const[loading,setLoading]=useState(false)
  const handleChange=(key,value)=>{
    setLoginData({...logindata,[key]:value})
  }

  const {isLoggedIn,userType} = useSelector((state) => state.auth)
useEffect(() => {
if(isLoggedIn)
navigate(userType=="AD"?"/admin/dashboard":"/")
console.log(userType,isLoggedIn);
}, [])
  const toast = useRef(null)
  const showError = () => {

    toast.current.show({severity:'error', summary: 'Error', detail:'Email or Password Incorrect', life: 3000});
}
  const handleSubmit=async(e)=>{
    e.preventDefault()
    setLoading(true)
    const result=await apiClient.post("/auth/login",logindata)
    setLoading(false)
    if(!result.ok) return showError()
    dispatch(login({token:result?.data?.token,userType:result?.data?.data?.role}))
    navigate((result?.data?.data?.role==="AD")?"/admin/dashboard":"/")
   
  }
  if (isLoggedIn) {
    return null; 
  }
  return (
    <>
    <Container component={Paper} maxWidth="xs">
    <Toast ref={toast} />
        <CssBaseline />
        
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        
        >
            <Typography component="h1" variant="h5">
           Login
          </Typography>
          <form onSubmit={handleSubmit}>
          <Box   sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={logindata.email}
              onChange={(e)=>handleChange("email",e.target.value)}
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              value={logindata.password}
              onChange={(e)=>handleChange("password",e.target.value)}
              id="password"
              autoComplete="current-password"
            />
        </Box>
        {!loading?
        <Grid container marginBottom={10} mt={5}>
              <Grid item xs>
                
                 <Button variant='contained' type='submit' style={{backgroundColor:"black"}}>Login</Button>
                
              </Grid>
              <Grid item>
               <Link to="/register">
                 <Button variant='contained' style={{backgroundColor:"black"}}>Create Account </Button>
                 </Link>
              </Grid>
            </Grid>: <Box sx={{ display: 'flex',justifyContent:"center" }}>
      <CircularProgress />
    </Box>}
        </form>
        </Box>

        </Container>
    </>
  )
}

export default Login