import { createSlice } from '@reduxjs/toolkit';
import {  useNavigate } from "react-router-dom";

const initialState = {
  search:""
};
console.log(initialState,"seeerach")
export const search = createSlice({
    name: "search",
    initialState,
    reducers: {
    
      searchData: (state, {payload}) => {
       
        state.search = payload;
       
  
      },
    
  
    }
  });
  
  // Action creators are generated for each case reducer function
  export const {
    searchData,
 
  } = search.actions;
  
  export default search.reducer;
  