
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import CounterSlice from "./CounterSlice";
import CartSlice from "./CartSlice";
import SearchSlice from "./Search";



const rootReducer = combineReducers({ auth: CounterSlice,cart:CartSlice,search:SearchSlice });

const persistConfig = {
  key: 'root',
  storage,
//   whitelist: ['auth'], // Specify which slices you want to persist (auth in this case)
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});


  
// middleware: (getDefaultMiddleware) =>
// getDefaultMiddleware({
// 	serializableCheck: {
// 		ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
// 	},
// }),
export const persistor = persistStore(store);
