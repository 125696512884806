import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProductCard from '../component/ProductCard'
import TuneIcon from '@mui/icons-material/Tune';
import { Link, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import PRODUCTS from '../component/Admin/_mock/products';
import apiClient from '../api/apiClient';
import { ProductSort } from '../component/Admin/sections/@dashboard/products';
import Paginate from '../component/mui/Paginate';
const limit=10
const Products = () => {
  const[sort,setSort]=useState()
const[products,setProducts]=useState()
const[filterproducts,setFilterProducts]=useState("")
const [count, setCount] = useState(0)
const [page, setPage] = useState(1)
const {id}=useParams()
const fetchProduct=async()=>{
  const urlParams=new URLSearchParams()
  id&&urlParams.set("category",id)
  const result =await apiClient.get(`/product/?page=${page}&limit=${limit}&${urlParams.toString()}&name=${filterproducts}&sort=${sort}`)
  if(!result.ok) return console.log("error")
  console.log(result?.data.results,"dsfsdfsdfsdf")
  setProducts(result?.data.results)
  if (!count||count!==result.data.count) setCount(result.data.count);
}




      const location = useLocation();
const data = location.state;
useEffect(() => {
  fetchProduct()
}, [id,filterproducts,sort,page])
const handlePageChange = (e, page) => {
  setPage(page)
}
  return (
    <>
    <Grid  container spacing={2} sx={{padding:"20px"}}>
        <Grid item xs={12} style={{display:"flex",justifyContent:"space-between",paddingX:"10px"}}>
            
            <div class="d-flex" style={{width:"70%"}}>
        <input class="form-control me-1" onChange={(e)=>setFilterProducts(e.target.value)} type="search" placeholder="Search" aria-label="Search"/>
       
      </div>
            
               
                

<div class="">
<ProductSort setSort={setSort} />
</div>
        </Grid>
        {products?.map((product)=>
        (
         
            <Grid item xs={12} md={4} lg={3}>
                <Link to='/singleproduct' state={product  } style={{textDecoration:"none",color:"inherit"}}>
            <ProductCard product={product} />
            </Link>
        </Grid>
        ))}
    </Grid>
    <div className='container d-flex justify-content-start'>


           <Paginate count={count} limit={limit} page={page} onChange={handlePageChange}/>
    </div>
    </>
  )
}

export default Products