import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, IconButton } from '@mui/material';
import img from "../assets/4.jpg"
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
export default function WishListCard() {
    const [showFullText, setShowFullText] = useState(false);
  const name = 'EP:0119 - Rugby Shorts - Navy'; // Replace with the actual name from API response

  const handleMouseEnter = () => {
    setShowFullText(true);
  }

  const handleMouseLeave = () => {
    setShowFullText(false);
  }
  return (
    <Card sx={{ maxWidth: 250 }} >
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={img}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div"  onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}>
          {showFullText ? name : name.split(' ').slice(0, 3).join(' ')+"..."}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            sm
          </Typography>
          <p className='fw-bold' style={{fontSize:"20px"}}>
            $50
          </p>
          <Button className='fw-bold' variant='contained' fullWidth style={{backgroundColor:"black",color:"white",}} >Add to Cart</Button>
        </CardContent>
        <IconButton sx={{ position: 'absolute', top: '10px', right: '10px' }}
          onClick={(e)=>{
            e.stopPropagation()
           
          }}
          aria-label="add to favorites">
            <CloseIcon />
          </IconButton>
      </CardActionArea>
    </Card>
  );
}