import React, { useState, useEffect, useContext } from "react";
import { fabric } from "fabric";

import "fabric-history";
import handlers from "../../modules/canvasHandlers";
import apiClient from "../../api/apiClient";
import { toast } from "react-toastify";
import { IconButton, Slider } from '@mui/material';

function CanvasZOom({values}) {
  const {initialZoomLevel,zoomLevel,setZoomLevel} =values
const diffInZoom=initialZoomLevel-1
const max=2+diffInZoom

  
    function zoomIn() {
      if(zoomLevel<max)
      setZoomLevel(value=>value +0.05) 
    }
    
    function zoomOut() {
     if(zoomLevel>initialZoomLevel)
      setZoomLevel(value=>value -0.05) 
    }
 

    

  function disableBrowserZoom(e) {
    if (e.ctrlKey) {
      e.preventDefault();
    }
  }
  
  document.addEventListener("wheel", disableBrowserZoom, { passive: false });





  


  
  

  
  

  return (
    <div className="row justify-content-end">
    <div
    // style={{width:"15%"}}
    className="col-3 col-lg-2"
id="zoomIn"
>
<ul>
<li className='' style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
<IconButton aria-label="add" onClick={zoomOut}>
<span className="material-icons "> zoom_out </span>
</IconButton>

<Slider
  value={zoomLevel} // Subtract the base zoom level and add 1 to get the relative zoom level
  step={0.05}
  min={initialZoomLevel}
  max={max}
  onChange={(e) => {
    e.stopPropagation();
    
    console.log((e.target.value) );
    setZoomLevel(e.target.value ); // Convert back to the absolute zoom level by adding the base zoom level and subtracting 1
  }}
  sx={{ width: "50%" }}
  aria-label="Default"
  valueLabelDisplay="auto"
  valueLabelFormat={(value) => `${Math.round(100*(value-diffInZoom))}%`}
/>


<IconButton aria-label="add" onClick={zoomIn}>
<span className="material-icons "> zoom_in </span>
</IconButton>
</li>
</ul>
</div>
    </div>
  )
}

export default CanvasZOom