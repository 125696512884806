import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCartTotal } from "../../../redux/CartSlice";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SlideMenu } from 'primereact/slidemenu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchIcon from '@mui/icons-material/Search';
import LocalMallIcon from '@mui/icons-material/LocalMall';


import { useState } from 'react';
import apiClient from "../../../api/apiClient";
import useApi from "../../../hooks/useApi";
function ResponsiveAppBar() {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isNestedDrawerOpen, setIsNestedDrawerOpen] = useState(false);
  const handleListItemClick = () => {
    setIsNestedDrawerOpen(true);
  };

  const handleToggleDrawer = (event) => {
    if (event.target.closest('[aria-expanded="true"]')) {
    
      event.stopPropagation();
      setIsDrawerOpen(!isDrawerOpen)
    }
  
  };
const {isLoggedIn,userType}=useSelector((state)=>state.auth)
 console.log(isLoggedIn,"login")
const [openItem, setOpenItem] = React.useState('');

const handleOpenSubmenu = (label) => {
  setOpenItem(openItem === label ? '' : label);
};
  const listItemData = [
   
    {
      label: "Shop",
      icon:<KeyboardArrowDownIcon/>,
      submenu: [
        { label: "driver ", link: "/admin/vendor/driver" },
        { label: "installer ", link: "/admin/vendor/installer" },
        { label: "photographer", link: "/admin/vendor/photographer" },
      ],
    },
    {
      label: "Official Merchandies",
      icon:<KeyboardArrowDownIcon/>,
      submenu: [
        { label: "driver ", link: "/admin/vendor/driver" },
        { label: "installer ", link: "/admin/vendor/installer" },
        { label: "photographer", link: "/admin/vendor/photographer" },
      ],
    },
    {
      label: "Team Wear",
      icon:<KeyboardArrowDownIcon/>,
      submenu: [
        { label: "corporation", link: "/admin/advertiser/corporation" },
        { label: "advertiser", link: "/admin/advertiser/advertiser" },
      ],
    },
    {
      label: "Discover",
      icon:<KeyboardArrowDownIcon/>,
      submenu: [
        { label: "corporation", link: "/admin/advertiser/corporation" },
        { label: "advertiser", link: "/admin/advertiser/advertiser" },
      ],
    },
    {
      label: "sale",
    },
  ];
 const apiCategories=useApi(()=>apiClient.get("/category/?limit=4"))
  const fetchCategories=async()=>{
    const result=await apiCategories.request()
    if(!result.ok) return console.log("error")
   
   }
   useEffect(() => {
    fetchCategories()
   
   }, [])
  const items = [
    {
        label:'Shop',
        icon:'pi pi-fw pi-file',
        items:[
            {
                label:'Mens Top',
                items:[
                {
                    label:'Rugby Shirts',
                },
                {
                    label:'T-shirts & Polos',
                },
                {
                    label:'Hoodies & Sweatshirts',
                },
                {
                    label:'Jackets',
                },
                {
                    label:'Vests',
                },
                {
                    label:'Long Sleeves',
                },
                {
                    label:'Base Layers',
                },
                {
                    label:'Shop All',
                },

                ]
            },
            {
                label:'Mens Bottom',
                items:[
                  {
                      label:'Base Layers',
                  },
                  {
                      label:'Trackpants & Joggers',
                  },
                  {
                      label:'Base Layers',
                  },
                  {
                      label:'Shop All',
                  },
      
              ]
            },
            {
                label:'Womens',
                icon:'pi pi-fw pi-external-link'
            },
            {
                label:'Equipments & Accessories',
                icon:'pi pi-fw pi-external-link'
            }
        ]
    },
    {
        label:'Edit',
        icon:'pi pi-fw pi-pencil',
        items:[
            {
                label:'Left',
                icon:'pi pi-fw pi-align-left'
            },
            {
                label:'Right',
                icon:'pi pi-fw pi-align-right'
            },
            {
                label:'Center',
                icon:'pi pi-fw pi-align-center'
            },
            {
                label:'Justify',
                icon:'pi pi-fw pi-align-justify'
            },

        ]
    },
    {
        label:'Users',
        icon:'pi pi-fw pi-user',
        items:[
            {
                label:'New',
                icon:'pi pi-fw pi-user-plus',

            },
            {
                label:'Delete',
                icon:'pi pi-fw pi-user-minus',

            },
            {
                label:'Search',
                icon:'pi pi-fw pi-users',
                items:[
                {
                    label:'Filter',
                    icon:'pi pi-fw pi-filter',
                    items:[
                        {
                            label:'Print',
                            icon:'pi pi-fw pi-print'
                        }
                    ]
                },
                {
                    icon:'pi pi-fw pi-bars',
                    label:'List'
                }
                ]
            }
        ]
    },
    {
        label:'Events',
        icon:'pi pi-fw pi-calendar',
        items:[
            {
                label:'Edit',
                icon:'pi pi-fw pi-pencil',
                items:[
                {
                    label:'Save',
                    icon:'pi pi-fw pi-calendar-plus'
                },
                {
                    label:'Delete',
                    icon:'pi pi-fw pi-calendar-minus'
                }
                ]
            },
            {
                label:'Archive',
                icon:'pi pi-fw pi-calendar-times',
                items:[
                {
                    label:'Remove',
                    icon:'pi pi-fw pi-calendar-minus'
                }
                ]
            }
        ]
    },
    {
        separator:true
    },
    {
        label:'Quit',
        icon:'pi pi-fw pi-power-off'
    }
];
console.log(apiCategories.data,"data");
const list = (
  <Box
   
    role="presentation"
    onClick={handleToggleDrawer}
    onKeyDown={handleToggleDrawer}
  >
    <SlideMenu model={items} viewportHeight={300} />
  </Box>
);

 
  const [hoveredPage, setHoveredPage] = useState(null);
  const navigate=useNavigate()
  return (
    <AppBar style={{backgroundColor:"#ffff",color:'black'}} position="static">
      <nav className="">
	<ul>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to={"/"}>
       <img src={require("../../../assets/logo.png")} className="img-fluid" style={{height:50,width:50}}/>
          </Link>

          <Box  sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(e)=>{
                e.stopPropagation()
                setIsDrawerOpen(true)
              }}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography>


          <Box
  className=''
  sx={{
    flexGrow: 1,
    justifyContent: 'center',
    display: { xs: 'none', md: 'flex' },
  }}
>





		<li><a className="navs" style={{cursor:"pointer"}}>Shop</a>
    <ul style={{zIndex:9000 ,width:"100vw"}} className="  ">
    <div className="main1 w-100 ">
     {apiCategories?.data?.map(item=>(
 <div className="m1 px-4 ">
    <Link to={"/collection/"+item._id} style={{ display: 'inline-block' }}>

    <h5>{item.name}</h5>
</Link>

{item?.children?.map(subItem=>(
  <Link to={"/collection/"+subItem._id} style={{ display: 'inline-block' }}>

{subItem.name}
</Link>
))  }

</div>
     ))        }

<div className="m1 px-4">
                
              
                <Link to={"/collection/"} style={{ display: 'inline-block' }}>

<h5> Shop All</h5>
</Link>
              </div>
         
              <div className=" px-4">
              <img
                src="https://cdn.shopify.com/s/files/1/0567/2537/3083/files/JMP_Exeter_Chiefs_v_Bath_Rugby_RS_045_1_2_ed350437-713c-4482-a70a-283d8b6ffdee_300x.jpg?v=1675341365"
                alt=""
                width={200}
                height={150}
              />
              </div>
              </div>
			</ul>
      </li>
		<li><a className="navs"  style={{cursor:"pointer"}} > Official Merchandise</a>
			<ul style={{zIndex:9000,width:"100vw"}}>
      
            <div className="main1 " style={{display:"flex",justifyContent:"space-evenly"}}>
              <div className="m1">
                <h5>Official Kits</h5>
                <Link to="/" >
                  Leicester Tigers
                </Link>
                <Link to="/" >
                  Exeter Chiefs
                </Link>
                <Link to="/" >
                  PR7s
                </Link>
                <Link to="/" >
                  Shop All
                </Link>
              </div>
              <div className="m1">
                <img
                  src="https://cdn.shopify.com/s/files/1/0567/2537/3083/files/EXETER_CHIEFS_LOGO_copy_180x.png?v=1674737978"
                  alt=""
                  height={"150px"}
                  width={"150px"}
                />
              </div>
              <div className="m1">
                <img
                  src="https://cdn.shopify.com/s/files/1/0567/2537/3083/files/LEICESTER_TIGERS_LOGO_340d1ec1-136d-475f-aea9-cbe591367f97_180x.png?v=1674663077"
                  alt=""
                  height={"150px"}
                  width={"150px"}
                />
              </div>
              <div className="m1">
                <img
                  src="https://cdn.shopify.com/s/files/1/0567/2537/3083/files/PR7s_LOGO_180x.png?v=1674738220"
                  alt=""
                  height={"150px"}
                  width={"150px"}
                />
              </div>
            </div>
         
			</ul>
		</li>
		<li><a className="navs"  style={{cursor:"pointer"}} >Team Wear</a>
			<ul style={{zIndex:9000,width:"100vw"}}>
     
            <div className="main1" style={{display:"flex",justifyContent:"space-around"}}>
              <div className="m1">
                <img
                  src="https://cdn.shopify.com/s/files/1/0567/2537/3083/files/TEAMWEAR_-_KIT_DESIGNER_300x.png?v=1657817829"
                  alt=""
                />
                <h6
                  className="mt-4"
                  style={{ textAlign: "center", fontWeight: "0.5rem" }}
                >
                  Kit Designer
                </h6>
              </div>
              <div className="m1">
                <img
                  src="https://cdn.shopify.com/s/files/1/0567/2537/3083/files/TEAMWEAR---ABOUT_300x.jpg?v=1674824640"
                  alt=""
                />
                <h6
                  className="mt-4"
                  style={{ textAlign: "center", fontWeight: "0.5rem" }}
                >
                  Teamwear Brochures
                </h6>
              </div>
              <div className="m1">
                <img
                  src="https://cdn.shopify.com/s/files/1/0567/2537/3083/files/TEAMWEAR_-_CLUBSHOPS_300x.png?v=1657817829"
                  alt=""
                />
                <p
                  className="mt-4"
                  style={{ textAlign: "center", fontWeight: "0.5rem" }}
                >
                  Club Shops
                </p>
              </div>
            </div>
         
			</ul>
		</li>
		<li><a className="navs"  style={{cursor:"pointer"}}>Discover</a>
    <ul style={{zIndex:9000,width:"100vw"}}>
    <div className="main1" style={{display:"flex",justifyContent:"space-around"}}>
              <div className="m1">
                <h5>More</h5>
                <Link to="/" className="nav-link">
                  About US
                </Link>
                <Link to="/" className="nav-link">
                  The SAMURAI Edit
                </Link>
                <Link to="/" className="nav-link">
                  Teamwear
                </Link>
                <Link to="/" className="nav-link">
                  Customer Service
                </Link>
              </div>
              <div className="m1">
                <h5>Partnerships</h5>
                <Link to="/" className="nav-link">
                  Exeter Chiefs
                </Link>
                <Link to="/" className="nav-link">
                  Leicester Tigers
                </Link>
                <Link to="/" className="nav-link">
                  Hong Kong Football Club
                </Link>
                <Link to="/" className="nav-link">
                  Premier Rugby Sevens (PR7s)
                </Link>
                <Link to="/" className="nav-link">
                  Dubai 7s
                </Link>
                <Link to="/" className="nav-link">
                  All Partnerships
                </Link>
              </div>
              <div className="m1">
                <img
                  src="https://cdn.shopify.com/s/files/1/0567/2537/3083/files/SQUARE_02_1_300x.jpg?v=1675958016"
                  alt=""
                  width={"350px"}
                  height={"350px"}
                />
                <p style={{fontSize:"0.8rem",textAlign:"center",alignItems:"center"}}>Leicester Tigers Partnership Extension</p>
              </div>
            </div>
    </ul>
    </li>
    <li><a className="navs"  style={{cursor:"pointer"}}>sale</a></li>
	


</Box>
          <Box className='gap-3' sx={{ flexGrow: 1,display:"flex",justifyContent:"flex-end" }}>
            {!isLoggedIn&&<Tooltip title="Login"><PermIdentityIcon aria-label="Login" style={{cursor:"pointer"}} onClick={()=>navigate("/login")}/></Tooltip>}
            {userType==="AD"&&<Tooltip title="Admin Profile"><PermIdentityIcon  aria-label="Login" style={{cursor:"pointer"}} onClick={()=>navigate("/admin/dashboard")}/></Tooltip>}
            
            {/* <FavoriteIcon style={{cursor:"pointer"}} onClick={()=>navigate("/wish-list")}/> */}
          {/* <SearchIcon/> */}
          <LocalMallIcon className="" style={{cursor:"pointer"}} onClick={()=>navigate("/cart")}/>
          </Box>
        </Toolbar>
      </Container>
      
      <Drawer
     
        anchor="left"
        open={isDrawerOpen}
        onClose={()=>setIsDrawerOpen(false)}
      >
           {list}
      </Drawer>
      
      </ul>
</nav>
    </AppBar>

  );
}

export default ResponsiveAppBar;






































