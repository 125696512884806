import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea, CardActions, IconButton } from '@mui/material';
import p2 from "./../assets/p2.jpg"
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useState } from 'react';
import ContactIcons from './ContactIcons';
import { useSelector } from 'react-redux';
export default function ProductCard({product}) {
const [fav,setFav]=useState(false)
const settingData=useSelector(state=>state.auth.settings)
console.log(product,"products")
  return (
  
    <Card className='' sx={{ width: 297 }}
  
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="247px"
          image={product?.product_image}
          alt={product?.name}
        />
        <CardContent>
          <Typography gutterBottom variant="text" component="div">
         {product?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {product?.name}
          </Typography>
       {product?.contact_for_price?
       <div className='d-flex align-items-center gap-2'>

        <Typography variant="body2" color="text.secondary">
        Contact for price
        </Typography>
        <ContactIcons phone={settingData?.phone} whatsapp={settingData?.whatsapp_no} 
        // message={`Hi!Inquiring for this product:${window.location.origin}`}
        />
       </div>
       :   <Typography variant="body2" color="text.secondary">
          ${product?.price}
          </Typography>}
{product?.on_demand?
   <Typography variant="body2" color="text.secondary">
   Customized Sizes Available
   </Typography>
:          <div className='d-flex gap-3'>
          <Typography variant="body2" color="">
         Available size:
          </Typography>
          {product?.sizes?.map((item)=>
          <Typography variant="body2" color="text.secondary">
         {item}
          </Typography>)}
          </div>}
        </CardContent>
        <CardActions  >
          {/* <IconButton sx={{ position: 'absolute', top: '10px', left: '10px',color :fav?"red":"inherit" }}
          onClick={(e)=>{
            e.stopPropagation()
            setFav(!fav)
          }}
          aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton> */}
          {product?.priceSale&&
          <Box className="px-3" sx={{ position: 'absolute', top: '10px', right: '10px',color :"White",backgroundColor:"red",borderRadius:"30px",height:"30px",padding:"5px",fontSize:"12" }}>
            <p>off {product?.priceSale} %</p>
          </Box>}
        </CardActions>
      </CardActionArea>
    </Card>
  );
}