import React, { useState, useEffect, useContext } from "react";
import { fabric } from "fabric";

import "fabric-history";
import handlers from "../../modules/canvasHandlers";
import apiClient from "../../api/apiClient";
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
function CanvasSaveHandler({values}) {
  const {showPreview}=values
    
    
  const{canvas}=useSelector(state=>state.auth)

   

  


    const {
      addFourPointedStar,
      addStar,
      initAligningGuidelines,
      initCenteringGuidelines,
      initCanvasHandlers,
      addCurvedText,
      imageUpload,
      LineSpace,
      addSticker,
      addArrow,
      addLine,
      remove,
      fontItalic,
      fontBold,
      addRectangle,
      addCircle,
      addTriangle,
      addNewText,
      fontFamily,
      fontSize,
      fillColor,
      setStroke,
      TextCenter,
      LineHeight,
      ObjCenter,
      ObjRotate,
      ZoomOut,
      ZoomIn,
      CopyObj,
      setBackgroundImage,
      sendBack,
      bringFront,
      TextLeft,
      TextRight,
      fontUnderline
    } = handlers();



    

   document.onkeydown = function(e) {
      
    if (e.keyCode==46) {
     
      let obj=canvas.getActiveObject()
      if(obj)
      canvas.remove(obj)
      canvas.renderAll();
    }
     // Check pressed button is Z - Ctrl+Z.
  if (e.ctrlKey&&e.keyCode === 90) {
    canvas.undo()
  }

  // Check pressed button is Y - Ctrl+Y.
  if (e.ctrlKey&&e.keyCode === 89) {
    canvas.redo()
  }
  
}
    function undo() {
      canvas.undo();
    }
  
    function redo() {
      canvas.redo();
    }
  
  
  
  
  

  return (
    <div className="d-flex gap-2">
    <button
      onClick={undo}
      id="undo"
      className="d-flex align-items-center gap-1 pointer border-0 bg-white"
    >
      <span className="material-icons"> undo </span>
    </button>
    <button
      onClick={redo}
      id="redo"
      className="d-flex align-items-center gap-1 pointer border-0 bg-white"
    >
      <span className="material-icons"> redo </span>
    </button>
    {/* <button className="save_as rounded-pill px-3 py-1">
  Save as Draft
</button> */}

<button
  onClick={showPreview}
  className="save_as_next rounded-pill px-3 py-1"
>
  NEXT <i className="bi bi-arrow-right ms-1" />
</button>
  </div>
  )
}

export default CanvasSaveHandler