import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Box, Container, Stack, Typography } from '@mui/material';
// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
// mock
import PRODUCTS from '../_mock/products';
import apiClient from '../../../api/apiClient';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { CleaningServices } from '@mui/icons-material';
import Searchbar from '../layouts/dashboard/header/Searchbar';
import Paginate from '../../mui/Paginate';
// ----------------------------------------------------------------------
const limit=10
export default function ProductsPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [load, setLoad] = useState(false);
  const[products,setProducts]=useState()
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const[sort,setSort]=useState()
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
const [search,setSearch]=useState("")
console.log(search,"ditaaaaa")
  const fetchProduct=async()=>{
    setLoad(true)
    // ?name=${search}
    const result =await apiClient.get(`/product/?page=${page}&limit=${limit}&name=${search||""}&sort=${sort||""}`)
    setLoad(false)
    if(!result.ok) return console.log("error")
    // console.log(result,"dsfsdfsdfsdf")
    if (!count||count!==result.data.count) setCount(result.data.count);
    setProducts(result?.data.results)
  }
  useEffect(() => {
    fetchProduct()
  }, [sort,page])
  const handlePageChange = (e, page) => {
    setPage(page)
  }
  return (
    <>
      <Helmet>
        <title> Dashboard: Products | Minimal UI </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Products
        </Typography>

        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1}>
            <Searchbar
              fetchData={fetchProduct}
              search={search}
              setSearch={setSearch}
            />
          </Stack>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort setSort={setSort} />
          </Stack>
        </Stack>

        {!load ? (
          <div>

            <ProductList products={products} fetchProduct={fetchProduct} />
            <Paginate count={count} limit={limit} page={page} onChange={handlePageChange}/>
          </div>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "Center" }}>
            <CircularProgress />
          </Box>
        )}
        {/* <ProductCartWidget /> */}
      </Container>
    </>
  );
}
