import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({ products,fetchProduct, ...other }) {
console.log(products,"jjjjjjjjjjjjjjjjjjjjjjjjjj")
  const navigate=useNavigate()
  return (
    <Grid container spacing={3} {...other}>
      {products?.map((product) => (
        

        <Grid key={product.id} item xs={12} sm={6} md={3}>
          <ShopProductCard product={product} fetchProduct={fetchProduct}/>
        </Grid>
      ))}
    </Grid>
  );
}
