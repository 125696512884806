import React, { useEffect, useRef, useState } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button, Switch, FormControlLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import apiClient from '../../../api/apiClient';
import CircularProgress from '@mui/material/CircularProgress';
import { Toast } from 'primereact/toast';
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import { handleErrors } from '../../../modules/handleErrors';
import FieldErrorMessage from '../components/FieldErrorMessage';
import { useParams } from 'react-router-dom';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  "XS",
  "S",
  "L",
  "XL"
];
function AddProduct() {
    const top100Films = [
        { title: 'XS', value: "XS" },
        { title: 'S', value: "S" },
        { title: 'M', value: "M" },
        { title: 'L', value: "L" },
        { title: 'XL', value: "XL" },
        { title: '2XL', value: "2XL" },
       
      ];
      const {id}=useParams()
      const [errors,setErrors]=useState()
  const [data, setData] = useState({
    name: '',
    category: '',
    price: '',
    sale: '',
    stock: '',
    discount:0,
    on_demand:false,
    contact_for_price:false,
    size: ["s","m"],
    description: '',
    other_images: [],
    product_image:"",
  });
  const [categories,setCategories]=useState()
  const [load,setLoad]=useState(false)
  const toast = useRef(null)
  const showError = () => {

    toast.current.show({severity:'error', summary: 'Error', detail:'Error', life: 3000});
}
  const showSuccess = () => {

    toast.current.show({severity:'success', summary: 'Success', detail:id?"Product Updated":'Product Added', life: 3000});
}
const handleImages = (key, value, index) => {
  if (key === "add") {
    setData((prevData) => ({
      ...prevData,
      other_images: [...prevData.other_images, ...Array.from(value)],
    }));
  } else if (key === "remove") {
    setData((prevData) => ({
      ...prevData,
      other_images: prevData.other_images.filter((x, i) => i !== index),
    }));
  }
};

  const handleChange = (key,value) => {
   console.log(key,value,"sdsdsdsdsd")
    setData({...data,[key]:value});
  };

  
  const handleSubmit =async (event) => {
    event.preventDefault();
    // Submit the form data
    const formData = new FormData();
    formData.append('name', data.name);
  formData.append('category', data.category);
  if(!data.contact_for_price){

    formData.append('price', data.price);
    formData.append('discount', data.sale);

  }
  else
  formData.append('contact_for_price', data.contact_for_price);


  if(!data.on_demand){

    formData.append('stock', data.stock);
    size.forEach((size, index) => {
      formData.append(`sizes[${index}]`, size);
    });
  }
  else
  formData.append('on_demand', data.on_demand);


  formData.append('description', data.description);
  if(typeof data.product_image!=="string")
  formData.append('product_image', data.product_image);
  for (const item of data.other_images) {
    formData.append("other_images",item)
  }
  setLoad(true)
  let result
  if (!id) result = await apiClient.post("/product", formData);
  else result = await apiClient.put(`/product/${id}`, formData);
    setLoad(false)
    if(!result.ok) return   setErrors(result.data.message)
    showSuccess()
    
    console.log(result,"result")
    console.log(formData,"hhhh")
    console.log(data)
  };
 const fetchCategory=async()=>{
  const result=await apiClient.get("/category")
  if(!result.ok) return console.log("error")
  setCategories(result.data.results)
 }
 const fetchProduct=async()=>{
  const result=await apiClient.get(`/product/${id}`)
  if(!result.ok) return console.log("error")
  setData({...data,...result.data.result,category:result.data.result.category._id})
  setSizez(result.data.result.sizes)
 }
 useEffect(() => {
  fetchCategory()
 }, [])
 useEffect(() => {
  if(id)
  fetchProduct()
 
 }, [id])
 const [size, setSizez] = React.useState([]);

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setSizez(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

 console.log(size,"sdsdsds")
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
      <Toast ref={toast} />
        <Grid item xs={12} md={6}>
        <TextField
            fullWidth
            id="price-input"
            label="Product Name"
            variant="outlined"
            name="product"
            value={data.name}
              onChange={(e)=>handleChange("name",e.target.value)}
          />
          <FieldErrorMessage errors={errors?.product}/>
        </Grid>
          <Grid item xs={12} md={6}>
          <FormControl fullWidth>
  <InputLabel id="category-label">Categories</InputLabel>
  <Select
    required
    labelId="category-label"
    id="category-select"
    name="category"
    value={data.category}
    onChange={(e) => handleChange("category", e.target.value)}
    label="Categories"
  >
    {categories?.map((category) => (
      [
        <MenuItem
          value={category._id}
          style={{ fontWeight: "bold" }}
        >
          {category.name}
        </MenuItem>,
        category.children?.map((subcategory) => (
          <MenuItem
            key={subcategory._id}
            value={subcategory._id}
            style={{ paddingLeft: "20px" }}
          >
            {subcategory.name}
          </MenuItem>
        ))

        ]
    ))}
  </Select>
</FormControl>


<FieldErrorMessage errors={errors?.category}/>



          </Grid>
          <Grid item xs={12} md={12} >
          <FormControlLabel control={<Switch 
            checked={data.contact_for_price}
            onChange={(e)=>handleChange("contact_for_price",!data.contact_for_price)}
           />} label="Contact for Price" />

        </Grid>
{!data.contact_for_price&&<>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            type='number'
            id="price-input"
            label="Price"
            variant="outlined"
            name="price"
            value={data.price}
            onChange={(e)=>handleChange("price",e.target.value)}
          />
            <FieldErrorMessage errors={errors?.price}/>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="sale-input"
            label="Discount"
            type='number'
            variant="outlined"
            name="Discount"
            value={data.discount}
            onChange={(e)=>handleChange("discount",e.target.value)}
          />
            <FieldErrorMessage errors={errors?.discount}/>
        </Grid>
</>}
        <Grid item xs={12} md={12}>
          <FormControlLabel control={<Switch 
            checked={data.on_demand}
            onChange={(e)=>handleChange("on_demand",!data.on_demand)}
           />} label="On Demand Products" />

        </Grid>
{!data.on_demand&&        <>
        
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="stock-input"
            type='number'
            label="Stock"
            variant="outlined"
            name="stock"
            value={data.stock}
            onChange={(e)=>handleChange("stock",e.target.value)}
          />
            <FieldErrorMessage errors={errors?.stock}/>
        </Grid>
        <Grid item xs={12} md={6}>
        <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Size</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={size}
          onChange={handleChange1}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={size.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FieldErrorMessage errors={errors?.sizes}/>
        </Grid>
        </>}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="description-input"
            label="Description"
            variant="outlined"
            name="description"
            value={data.description}
            onChange={(e)=>handleChange("description",e.target.value)}
          />
            <FieldErrorMessage errors={errors?.description}/>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className='d-flex gap-5'>
<div>

          <label htmlFor='product'>Product Image</label><br/>
        <input type="file" id='product' onChange={(e)=>handleChange("product_image",e.target.files[0])} />
</div>
{data.product_image&&<img
          src={typeof data.product_image==="string"?data.product_image:URL.createObjectURL(data.product_image)}
          style={{
            height: '50px',
            width: '50px'
          }}
        />}
          </div>
          <FieldErrorMessage errors={errors?.product_image}/>
        </Grid>
        <Grid item xs={12}>
        <label htmlFor='product'>Other Product Images</label><br/>
             <input type="file" multiple onChange={(e)=>handleImages("add",e.target.files)} />
          <div className='d-flex gap-5 justify-content-start flex-wrap mt-3'>
          
  { data.other_images.map((file, index) => (
      <div style={{position:"relative"}}>

        <img
          key={index}
          src={typeof file==="string"?file:URL.createObjectURL(file)}
          alt={`Selected Image ${index}`}
          style={{
            height: '200px',
            width: '200px',
            borderRadius:10
          }}
        />
        <i style={{position:"absolute",top:5,right:5}} onClick={()=>handleImages("remove","",index)} class="bi bi-trash-fill text-danger"></i>
      </div>
    ))
}

       
          </div>
          <FieldErrorMessage errors={errors?.other_images}/>
        </Grid>
        <Grid item xs={12}>
        {!load?  <Button fullWidth variant='contained' type="submit">{id?"Update":"Submit"}</Button>
        :<Box sx={{ display: 'flex',justifyContent:"Center" }}>
        <CircularProgress />
      </Box>}
        </Grid>
      </Grid>
    </form>
  );
}

export default AddProduct;
