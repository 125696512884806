// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/admin/dashboard',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'user',
  //   path: '/admin/user',
  //   icon: icon('ic_user'),
  // },
  {
    title: 'product',
    path: '/admin/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'Add New Product',
    path: '/admin/new-product',
    icon: icon('ic_cart'),
  },
  {
    title: 'Add New Category',
    path: '/admin/new-category',
    icon: icon('ic_cart'),
  },
  {
    title: 'New Mockup',
    path: '/admin/mockup/create',
    icon: icon('ic_cart'),
  },
  
  {
    title: 'blog',
    path: '/admin/blogs',
    icon: icon('ic_blog'),
  },
  {
    title: 'Profile',
    path: '/admin/profile',
    icon: icon('ic_lock'),
  },
  {
    title: 'Settings',
    path: '/admin/settings',
    icon: icon('ic_lock'),
  },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
