import { Divider, Grid } from '@mui/material'
import React from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import WishListCard from '../component/WishListCard';

const WishList = () => {
  return (
    <>
    <div className='container'>
       <div className='d-flex justify-content-between align-items-center'>
        <p style={{fontSize:"24px"}}>My Wishlist</p>
        <p>Guest Shopper</p>
       </div>
       <Divider className='fw-bold' />
       <div className='d-flex justify-content-end align-items-center' style={{padding: '10px 0'}} >
       <MoreVertIcon fontSize='medium'   />
       </div>
       <Divider/>
       <div className='container p-4 d-flex gap-3'  >
        <Grid container>
        <Grid item xs={6} md={4} lg={3}>
        <WishListCard/>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
        <WishListCard/>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
        <WishListCard/>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
        <WishListCard/>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
        <WishListCard/>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
        <WishListCard/>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
        <WishListCard/>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
        <WishListCard/>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
        <WishListCard/>
        </Grid>
        </Grid>
       </div>
    </div>
    </>
  )
}

export default WishList