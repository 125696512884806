import { Button, ButtonBase, Divider, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import img from "../assets/p2.jpg"
import { InputNumber } from 'primereact/inputnumber';
import { useDispatch, useSelector } from 'react-redux'
import { getCartTotal, removeItem } from '../redux/CartSlice'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const Cart = () => {
    // const {cart}=useSelector((state)=>state.cart)
    // console.log(cart,"jjjjjj")
   
    // const dispatch=useDispatch()
    // useEffect(() => {
    //   dispatch(getCartTotal())
    // }, [cart])
    const[inc,setinc]=useState(1)
    const cart = JSON.parse(localStorage.getItem('cart')) || [];

    // Display the cart contents
    console.log(cart,"old");
    function changeQuantity(itemId, newQuantity) {
        // Check if the cart already exists in local storage
        let cart = JSON.parse(localStorage.getItem('cart')) || [];
      console.log(itemId,newQuantity)
        // Find the index of the item with the given itemId in the cart
        const itemIndex = cart.findIndex(item => item.id === itemId);
      console.log(itemIndex,"mnmn")
        if (itemIndex !== -1) {
          // If the item exists in the cart, update its quantity
          cart[itemIndex].quantity = newQuantity;
          console.log("first")
        }
      console.log(cart,"op")
        // Update the cart in local storage
        localStorage.setItem('cart', JSON.stringify(cart));
      }
      
    const handleInc=(itemId,quan)=>{
        let inc= quan+1
        console.log(quan,inc,"kl")
        changeQuantity(itemId, inc);
    }
    const handleDec=(itemId,quan)=>{
        if(quan>0){
        let inc= quan-1
        console.log(quan,inc,"kl")
        changeQuantity(itemId, inc);}
    }
    useEffect(() => {
        // This code will run whenever handleInc is called
        // You can put your desired side effects or state updates here
      }, [inc]);
  return (
   <>
   <div className='container'>
    <div className='d-flex justify-content-center p-3' >
        <h1 className='fw-bold'>Shopping cart</h1>
    </div>
    {/* <div className='d-flex'>
        <Grid container>
            <Grid item xs={12} md={8}>
                <p>Product</p>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className='d-flex'>
                <Grid item xs={12} >
                <p>Price</p>
                </Grid>
                <Grid className='px-5 ' item xs={12} >
                <p>Quantity</p>
                </Grid>
                <Grid item xs={12} >
                <p>Toal</p>
                </Grid>
                </div>
            </Grid>
        </Grid>
    </div> */}
    <Divider sx={{ fontWeight: '800px' }} />
        {/* <div className='d-flex'>
        <Grid container>
            {cart.map((item)=>(
              <> 
            <Grid className='p-2' item xs={12} md={8}>
                <div className='d-flex gap-2'>
                    <img src={item.image} style={{height:"100px",width:"100px" ,objectFit:"cover"}}/>
                    <div>
                        <p className='mb-0'>{item.name}</p>
                        <p>{item.size}</p>
                    </div>
                </div>
            </Grid>
            <Grid className='p-2' item xs={12} md={4}>
                <div className='d-flex'>
                <Grid item xs={12} >
                <p>{item.price}</p>
                </Grid>
                <Grid className='px-5 py-2' item xs={12} >
                <InputNumber value={item.quantity}  showButtons buttonLayout="horizontal" style={{ width: '1rem',height:"2rem" }} 
    decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
               <Button variant='text' style={{color:"black",fontSize:"12px"}} >remove</Button>
                </Grid>
                <Grid item xs={12} >
                <p>$20</p>
                </Grid>
                </div>
            </Grid>
           </>
            ))}
        </Grid>
    </div> */}
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Size</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Total Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cart.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              <img src={row.image} style={{height:"100px",width:"100px" ,objectFit:"cover"}}/>
              </TableCell>
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="right">{row.size}</TableCell>
              <TableCell align="right">{row.price}</TableCell>
              <TableCell align="right">
              <div class="input-group w-auto justify-content-end align-items-center">
                <Button onClick={()=>{
            
            handleDec(row.id,row.quantity)
            setinc(inc+1)
            }}>-</Button>
         <input type="number" step="1" max="10" value={row.quantity} name="quantity" class="quantity-field border-0 text-center w-25"/>
         <Button onClick={()=>{
            
            handleInc(row.id,row.quantity)
            setinc(inc+1)
            }}>+</Button>
         </div>
                   </TableCell>
              <TableCell align="right">{row.price * row.quantity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
   </div>
   </>
  )
}

export default Cart