import React from "react";
import "../../style.css";

const Hero = () => {
  return (
    <div className=" container  ">
     <div className="row  d-flex mb-5 ">
      <div    className="container1 col-md-6 col-sm-12 "
        
      >
        <div className="" style={{ display: "flex", flexDirection: "column", backgroundSize: '100% 100%',
    backgroundRepeat: 'none',justifyContent: "flex-end" ,backgroundImage: `url('https://cdn.pixabay.com/photo/2013/10/17/18/20/believe-197091_960_720.jpg')`,
  height: "500px"}}>
 <div className=" mb-5 " >
        <p className="bottom-center">Official Merchandise</p>
        <h3 className="bottom-center1">Rugby Jerseys</h3>
        <button type="button" class="btn btn-light bottom-center2">
          Shop Now
        </button>
   </div>    
</div>
      </div>
      <div className="container1 col-md-6 col-sm-12">
         <div className="" style={{ display: "flex",backgroundSize: '100% 100%',
    backgroundRepeat: 'none', flexDirection: "column", justifyContent: "flex-end" ,backgroundImage: `url('https://cdn.pixabay.com/photo/2016/07/13/16/00/box-1514845__340.jpg')`,
  height: "500px"}}>
        {/* <p className="bottom-center">Official Merchandise</p> */}
        <div className="mb-5">
        <h3 className="bottom-center1 w-100">Upto 70% off Outlet</h3>
        <button type="button" class="btn btn-light bottom-center2">
          Shop Now
        </button>
        </div>
      </div>
   </div>
   </div>
    </div>
  );
};

export default Hero;
