import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fabric } from "fabric";
import "fabric-history";
import handlers from "../../modules/canvasHandlers";
import { toast } from "react-toastify";
import { pink } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import LoadingOverlay from "../../component/mui/LoadingOverlay";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import CanvasZOom from "../../component/canvas/CanvasZoom";
import MainCanvasHandlers from "../../component/canvas/MainCanvasHandlers";
import SubCanvasHandlers from "../../component/canvas/SubCanvasHandlers";
import CanvasSaveHandler from "../../component/canvas/CanvasSaveHandler";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCanvas } from "../../redux/CounterSlice";
import apiClient from "../../api/apiClient";

export default function AddNewCard() {
  const { state } = useLocation();
 const dispatch=useDispatch()
  const {canvas}=useSelector(state=>state.auth)
  const [next, setNext] = useState(2);
  const [sub_categories, setSub_categories] = useState([]);
  const [SlectedColor, setSlectedColor] = useState("white");
  const [zoomLevel, setZoomLevel] = useState(1);
  const maxHeight =  1899 / 3;
  const intialControls = { text: false,sticker:false, obj: false };
  const [controls, setControls] = useState(intialControls);
  const [initialZoomLevel,setInitialZoomLevel]=useState()
  const outerDivRef = useRef(null);
  const zoomableDivRef = useRef(null);

  const adjustZoomLevel = () => {
    if (!outerDivRef.current || !zoomableDivRef.current) return;

    const outerHeight = outerDivRef.current.clientHeight;
    const zoomableHeight = zoomableDivRef.current.clientHeight;
    const initialZoomLevel = outerHeight / zoomableHeight;
    setInitialZoomLevel(initialZoomLevel - 0.2)
    setZoomLevel(initialZoomLevel - 0.2);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      adjustZoomLevel();
    });

    if (outerDivRef.current && zoomableDivRef.current) {
      resizeObserver.observe(outerDivRef.current);
      resizeObserver.observe(zoomableDivRef.current);
    }

    return () => {
      if (outerDivRef.current && zoomableDivRef.current) {
        resizeObserver.unobserve(outerDivRef.current);
        resizeObserver.unobserve(zoomableDivRef.current);
      }
    };
  }, []);

  const zoomableDivStyle = {
    transform: `scale(${zoomLevel})`,
    transition: "transform 0.3s",
    transformOrigin: "top center",
  };

  useEffect(() => {
   
    dispatch(setCanvas(initCanvas()));
  }, []);

  const initCanvas = () => {
    const newCanvas = new fabric.Canvas("c", {
      height: maxHeight,
      width: 1299 / 3,
      preserveObjectStacking: true,
      backgroundColor: "white",
    });

    initCenteringGuidelines(newCanvas);
    initAligningGuidelines(newCanvas);
    initCanvasHandlers(newCanvas, normal, update);

    return newCanvas;
  };
  const {
    addFourPointedStar,
    addStar,
    initAligningGuidelines,
    initCenteringGuidelines,
    initCanvasHandlers,
    addCurvedText,
    imageUpload,
    LineSpace,
    addSticker,
    addArrow,
    addLine,
    remove,
    fontItalic,
    fontBold,
    addRectangle,
    addCircle,
    addTriangle,
    addNewText,
    fontFamily,
    fontSize,
    fillColor,
    setStroke,
    TextCenter,
    LineHeight,
    ObjCenter,
    ObjRotate,
    ZoomOut,
    ZoomIn,
    CopyObj,
    setBackgroundImage,
    sendBack,
    bringFront,
    TextLeft,
    TextRight,
    fontUnderline,
  } = handlers();
  let initialFont = {
    fontFamily: "",
    fontSize: "",
  };
  const [font, setFont] = useState(initialFont);



  const initial = {
    name: "",
    price: "",
    discount: "",
    type: state?.type,
    tags: "",
    description: "",
    image: null,
    data: null,
    category: state?.id || "",
    discount_in_percent: true,
    colors: [],
  };
  const [canvasData, setCanvasData] = useState(initial);
  function handleChange(key, value) {
    if (key == "discount" && canvasData.discount_in_percent && value > 100)
      return;
    if (key == "discount_in_percent" && value == true)
      return setCanvasData({ ...canvasData, [key]: value, discount: "" });
    setCanvasData({ ...canvasData, [key]: value });
  }

  function update(e) {
    if (!e.selected) return;
    var obj = e.selected[0];

    if (obj.text) {
      setControls({
        text: true,
        sticker:false,
        obj: false,
      });
      setFont({ fontSize: obj.fontSize, fontFamily: obj.fontFamily });
    }
  else  if (obj.id=="sticker") {
      setControls({
        text: false,
        sticker:true,
        obj: false,
      });
      console.log("sticker")
    } else {
      setControls({
        text: false,
        obj: true,
      });
    }
  }
  function normal(e) {
    console.log("normal");
    console.log("hide");
    setControls(intialControls);
    setFont({ fontSize: "", fontFamily: "" });
  }

  async function getCanvasBlob() {
    let dataURL = canvas.toDataURL({
      format: "jpg",
      quality: 1,
    });
    dataURL = await watermarkImage(dataURL);
    console.log(dataURL);
    var data = await fetch(dataURL).then((res) => res.blob());
    return data;
  }

  async function showPreview() {
    let blobImage = await getCanvasBlob();
    setCanvasData({
      ...canvasData,
      data: JSON.stringify(canvas.toDatalessJSON(["selectable", "id"])),
      image: blobImage,
    });
    setNext(next + 1);
  }

  async function watermarkImage(dataURL, watermarkText = "Greetings") {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const image = new Image();
    image.src = dataURL;
    await new Promise((resolve) => (image.onload = resolve));
    const canvasWidth = image.width;
    const canvasHeight = image.height;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    // initializing the canvas with the original image
    context.drawImage(image, 0, 0, canvasWidth, canvasHeight);

    // Set the position and size of the watermark text
    context.font = `${canvasHeight * 0.05}px Arial`;
    context.fillStyle = "grey";
    const text = watermarkText;
    const textWidth = context.measureText(text).width;
    const textHeight = canvasHeight * 0.2;
    const textX = canvasWidth - textWidth - 20;
    const textY = canvasHeight - 20;

    // Draw the watermark text on the canvas
    context.globalAlpha = 0.5;
    context.fillText(text, textX, textY);

    return canvas.toDataURL();
  }

  async function download() {
    const base64Url = canvas.toDataURL({
      format: "jpg",
      quality: 1,
      multiplier: 10,
    });
    const result = await watermarkImage(base64Url);
    console.log(result);

    // Download the watermarked image
    var dl = document.createElement("a");
    document.body.appendChild(dl); // This line makes it work in Firefox.
    dl.setAttribute("href", result);
    dl.setAttribute("download", "logo.png");
    dl.click();
  }

  return (
    <>
      <div className={next != 2 && "d-none"}>
        <div id="main canvas-div"  style={{ height: "100vh",overflow:"hidden" }}>
          <div className="row justify-content-between bg-white  align-items-center px-3">
            <div className="col-3">
            {/* <DashboardLogoButton/> */}
            </div>
            <div className="col-6">
              <MainCanvasHandlers admin />
            </div>
            <div className="col-3">
              <CanvasSaveHandler values={{ next, setNext, showPreview }} />
            </div>
          </div>

          <div
            ref={outerDivRef}
            className="position-relative  "
            style={{
              paddingTop: 65,
              backgroundColor: "#F2F2F2",
              height: "95%",
            }}
          >
            <div style={{ position: "absolute", top: 0, width: "100%" }}>
              <SubCanvasHandlers
                values={{ canvasData, setCanvasData, controls, setControls,font }}
              />
            </div>

            <div
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
                height: "95%",
              }}
            >
              <div
                ref={zoomableDivRef}
                style={zoomableDivStyle}
                className="row"
              >
                <div
                  className="col d-flex justify-content-center align-items-center"
                  id="canvasContainer"
                >
                  <canvas id="c" />
                </div>
              </div>
            </div>
            <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
             <CanvasZOom values={{initialZoomLevel,zoomLevel,setZoomLevel}}  />
            </div>
          </div>
        </div>
      </div>
    

      {next == 3 && (
        <DesignReady
          canvasData={canvasData}
          download={download}
          handleChange={handleChange}
          categories={sub_categories}
          next={next}
          setNext={setNext}
        />
      )}
    </>
  );
}


function DesignReady({
  canvasData,
  handleChange,
  download,
  categories,
  next,
  setNext,
}) {
  const [loading, setLoading] = useState(false);
  async function handleSubmit(e) {
   
  }

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-7 d-md-flex justify-content-md-center text-md-start text-center mt-4">
            <div className="working_on_color"></div>
            <div className="">
              <button
                onClick={() => setNext(next - 1)}
                className="save_as_next rounded-pill px-3 py-1 mt-lg-0 mt-3"
              >
                Back to editor
              </button>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-7">
            <div className="row my-3">
              <div className="col">
                <h6 className="text_aling mb-3">Your Final Design Is Ready!</h6>
                <div className="text-center justify-content-center align-items-center d-flex">
                  <img
                    src={URL.createObjectURL(canvasData.image)}
                    className="canva_img"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="text-center d-flex justify-content-center">
                <div className=" gap-3 d-flex justify-content-md-start justify-content-center mb-3">
                  <div>
                    {/* <button
                      className="save_as_next rounded-pill px-sm-3 px-2 py-2"
                      data-bs-toggle="modal"
                      data-bs-target="#Shear"
                    >
                      <img src="./images/reply.png" alt="" /> Share
                    </button> */}
                  </div>
                  <div>
                    <button
                      className="save_as_next rounded-pill px-3 py-2"
                      onClick={download}
                    >
                      Download
                    </button>
                  </div>
                  <div>
                    {loading && <LoadingOverlay open={loading} />}

                    <button
                      className="save_as_next rounded-pill px-3 py-2"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className="modal" id="Shear">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header header_color">
                      <h6 className="modal-title">Share Design </h6>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                      />
                    </div>
                    <div className="modal-body">
                      <p>Share to</p>
                      <label
                        className="form-check-label w-100"
                        htmlFor="radio1"
                      >
                        <div className="d-flex justify-content-between p-2 whatsapp_div_bg_color">
                          <div>
                            {/* <img
                              src={require("../../assets/popup_whatsapp.png")}
                              className="me-3 whatsapp_img_size"
                              alt=""
                            />{" "} */}
                            Whatsapp
                          </div>
                          <div className="my-auto">
                            <input
                              type="radio"
                              className
                              id="radio1"
                              name="optradio"
                              defaultValue="option1"
                            />
                            {/* {/* <label class="form-check-label" for="radio1"></label> * /} */}
                          </div>
                        </div>
                      </label>
                      <label
                        className="form-check-label w-100"
                        htmlFor="radio2"
                      >
                        <div className="d-flex justify-content-between p-2 mt-2 whatsapp_div_bg_color">
                          <div>
                            {/* <img
                              src={require("../../assets/popup_email.png")}
                              className="me-3"
                              alt=""
                            /> */}
                            <label>Email</label>
                          </div>
                          <div className="my-auto">
                            <input
                              type="radio"
                              className
                              id="radio2"
                              name="optradio"
                              defaultValue="option1"
                            />
                            {/* {/* <label class="form-check-label" for="radio2"></label> * /} */}
                          </div>
                        </div>
                      </label>
                      <div>
                        {
                          <button
                            type="submit"
                            className="share_submit px-4 py-1 mt-3"
                          >
                            Share
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function AddColors({ values }) {
  const { show, onHide, canvasData, handleChange } = values;
  const [selectedColor, setSelectedColor] = useState([]);
  const handleClick = () => {
    handleChange("colors", [...canvasData.colors, selectedColor]);
    setSelectedColor("");
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header handleClose>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label className="m" htmlFor="clor">
              Select Card Colors
            </label>{" "}
          </div>
          <input
            required
            type="color"
            id="clor"
            onChange={(e) => setSelectedColor(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              handleClick();
              onHide();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
