import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PRODUCTS from "../../Admin/_mock/products";
import { Carousel } from "primereact/carousel";

import ProductCard from "../../ProductCard";
import "primereact/resources/themes/saga-blue/theme.css"; // Replace with your desired theme
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import apiClient from "../../../api/apiClient";

import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";
const Hero1 = () => {
  const[products,setProducts]=useState([])
  const [fav,setFav]=useState(false)
  const fetchProduct=async()=>{
    const result =await apiClient.get("/product/")
    if(!result.ok) return console.log("error")
    console.log(result?.data.results,"dsfsdfsdfsdf")
    setProducts(result?.data.results)
  }
  
  useEffect(()=>{
    fetchProduct()
  },[])
  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const productTemplate = (product) => {
  
    return (
      <div className=" d-flex justify-content-center" >
        <Link
          to="/singleproduct"
          state={product}
          style={{ textDecoration: "none", color: "inherit" }}
        >
        
          <ProductCard product={product}  />
        </Link>
      </div>
    );
  };
  return (
    <div className="card">
      <Carousel
        value={products}
        numVisible={4}
        numScroll={1}
        responsiveOptions={responsiveOptions}
        circular
        autoplayInterval={3000}
        itemTemplate={productTemplate}
        draggable
        pt={{
          indicatorButton: { className: 'border-round-lg' }
      }}
      className="pt-5"
      />
    </div>
  );
};

export default Hero1;
