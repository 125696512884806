import React, { useEffect, useRef, useState } from 'react'
import apiClient from '../../../api/apiClient'
import { Toast } from 'primereact/toast';
import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { initial } from 'lodash';
const AddCategory = () => {
    let iitialstate={
        parentCategory:"",
        name:""
    }
    const[data,setData]=useState([])
    const [load,setLoad]=useState(false)
  const toast = useRef(null)
  const showError = () => {

    toast.current.show({severity:'error', summary: 'Error', detail:'Error', life: 3000});
}
const showSuccess = () => {

    toast.current.show({severity:'success', summary: 'Success', detail:'Category Added', life: 3000});
}

const [text, setText] = React.useState(iitialstate);
  
  const onChange = (key ,value) => {
    setText({...text,[key]:value})
  }
    const fetchData=async()=>{
        const result=await apiClient.get("/category")
        if(!result.ok) return showError()
        console.log(result,"dfdfd")
        setData(result.data.results)
    }

    useEffect(() => {
      fetchData()   }, [])

      const handleSubmit=async(e)=>{
        e.preventDefault()
        setLoad(true)
        if(!text.parentCategory)
        delete text.parentCategory
    const result=await apiClient.post("/category",text)
    if(!result) return showError()
    setLoad(false)
    showSuccess()
    setText(iitialstate)
      }
    
  return (
    <>
        <div className="d-flex">
        <Toast ref={toast} />
            <form onSubmit={handleSubmit}>
        <Grid container spacing={2} className='w-100'>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type=''
            id="price-input"
            label="Category"
            variant="outlined"
            name="sub category"
            value={data.name}
            onChange={(e)=>onChange("name",e.target.value)}
          />
        </Grid>
        <Grid item xs={12} >
            <FormControl fullWidth>
              <InputLabel id="category-label">Parent Categories</InputLabel>
              <Select
                labelId="category-label"
                id="category-select"
                name=" Parent category"
                value={data.parentCategory}
                onChange={(e)=>onChange("parentCategory",e.target.value)}
                label="Parent Categories"
              >
                {data?.map((item)=>
                <MenuItem value={item._id}>{item.name}</MenuItem>
                )}
              </Select>
            </FormControl>
            <Typography>select a parent category to add it as sub category otherwise blank</Typography>
          </Grid>
       
        <Grid item xs={12}>
        {!load?
          <Button fullWidth variant='contained' type="submit">Submit</Button>
     :<Box sx={{ display: 'flex',justifyContent:"Center" }}>
         <CircularProgress />
       </Box>} 
        </Grid>
          </Grid>
        </form>
    </div>
    </>
  )
}

export default AddCategory