import { createSlice } from '@reduxjs/toolkit';
import {  useNavigate } from "react-router-dom";
import apiClient from '../api/apiClient';

const initialState = {
  token:  null,
  userType:  null,
  isLoggedIn: false,
  debugMode: (process.env.REACT_APP_DEBUG=="true"||process.env.REACT_APP_DEBUG=="True"),
  canvas: null,
  settings:{}
};

export const counterSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
  
    login: (state, {payload}) => {
      state.token = payload.token;
      state.userType=payload.userType
      state.isLoggedIn=true

    },
    logout: (state) => {
        state.token = null;
        state.isLoggedIn=false
      },
      setCanvas: (state, { payload }) => {
        state.canvas = payload;
      },
      setSettingsData: (state, { payload }) => {
        state.settings = payload;
      },
    
    
 


  }
});

export const fetchSettings = () => async (dispatch) => {

    const result = await apiClient.get('/setting/')
    // console.log(data);
    if(!result.ok) return console.log("Error fetching data:",result.data);
   
      dispatch(counterSlice.actions.setSettingsData(result?.data?.result)); // Dispatch the action to set the user data
    

};
// Action creators are generated for each case reducer function
export const {
login,
logout,
setCanvas
} = counterSlice.actions;

export default counterSlice.reducer;
