import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Input, Slide, Button, IconButton, InputAdornment, ClickAwayListener } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// component
import Iconify from '../../../components/iconify';
import { useDispatch } from 'react-redux';
import { searchData } from '../../../../../redux/Search';

// ----------------------------------------------------------------------

const HEADER_MOBILE = 50;

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function Searchbar({search,setSearch,fetchData}) {


  return (

          <StyledSearchbar>
            <Input
              autoFocus
              fullWidth
              onChange={(e)=> setSearch(e.target.value)}
              onKeyUp={(e)=>e.key==="Enter"&&fetchData()}
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" onClick={()=>fetchData()}>
              Search
            </Button>
          </StyledSearchbar>
  
  );
}
