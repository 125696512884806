import React, { useState, useEffect, useContext } from "react";
import { fabric } from "fabric";
import "fabric-history";
import handlers from "../../modules/canvasHandlers";
import apiClient from "../../api/apiClient";
import { toast } from "react-toastify";
import ColorPaletteFill from '../../component/ColorPaletteFill';
import { IconButton, Slider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import { useSelector } from "react-redux";
const fonts=[
 

  {
    name:"Fenoon  تحيات",
    font:"Fenoon"},
  {
    name:"Wafir  تحيات",
  font:"Wafir"},
  {
    name:"Tamema  تحيات",
  font:"Tamema"},
  {
    name:"Tamema Embossed  تحيات",
  font:"Tamema Embossed"},
  {
    name:"Talasem  تحيات",
  font:"Talasem"},
  {
    name:"Shafrah  تحيات",
  font:"Shafrah"},
  {
    name:"Rakan  تحيات",
  font:"Rakan"},
  {
    name:"Nishan  تحيات",
  font:"Nishan"},
  {
    name:"Naghamah  تحيات",
  font:"Naghamah"},
  {
    name:"Maharah  تحيات",
  font:"Maharah"},
  {
    name:"Lamhah  تحيات",
  font:"Lamhah"},
  {
    name:"Laftah  تحيات",
  font:"Laftah"},
  {
    name:"Labaqah  تحيات",
  font:"Labaqah"},
  {
    name:"Kufigraph  تحيات",
  font:"Kufigraph"},
  {
    name:"Kaleem  تحيات",
  font:"Kaleem"},
  {
    name:"Kaleel  تحيات",
  font:"Kaleel"},
  {
    name:"Jazeel  تحيات",
  font:"Jazeel"},
  {
    name:"Ithra  تحيات",
  font:"Ithra"},
  {
    name:"Inseyab  تحيات",
  font:"Inseyab"},
  {
    name:"Origami Notepad  تحيات",
  font:"Origami Notepad"},
  {
    name:"Ahlan  تحيات",
    font:"Ahlan"},
  {
    name:"Atyaaf  تحيات",
  font:"Atyaaf"},
  {
    name:"Bareeq  تحيات",
  font:"Bareeq"},
  {
    name:"Zelda-Regular  تحيات",
  font:"Zelda-Regular"},
  {
    name:"Etlalah  تحيات",
  font:"Etlalah"},
  {
    name:"Tashkeel-Monochrome  تحيات",
  font:"Tashkeel-Monochrome"},
  {
    name:"Dahka  تحيات",
  font:"Dahka"},
  

  {
    name:"Adine ",
    font:"Adine"},
  {
    name:"Almeda-Regular ",
    font:"Almeda-Regular"},
  {
    name:"BS-Regular ",
    font:"BS-Regular"},
  {
    name:"AEOLUS ",
    font:"AEOLUS"},
  {
    name:"AGENCYB ",
    font:"AGENCYB"},
  {
    name:"AGENCYR ",
    font:"AGENCYR"},
  {
    name:"AguafinaScript ",
    font:"AguafinaScript"},
  {
    name:"Akka ",
    font:"Akka"},
  {
    name:"Akronim ",
    font:"Akronim"},
  {
    name:"AlexBrush ",
    font:"AlexBrush"},
  {
    name:"Allura ",
    font:"Allura"},
  {
    name:"Almo ",
    font:"Almo"},
  {
    name:"AmazOOSTROVFine ",
    font:"AmazOOSTROVFine"},
  {
    name:"Amita ",
    font:"Amita"},
  {
    name:"AMRTEXTN ",
    font:"AMRTEXTN"},
  {
    name:"AnnieUseYourTelescope ",
    font:"AnnieUseYourTelescope"},
  {
    name:"Arizonia ",
    font:"Arizonia"},
  {
    name:"BankScrd ",
    font:"BankScrd"},
  {
    name:"Ballet ",
    font:"Ballet"},
  {
    name:"BARR ",
    font:"BARR"},
  {
    name:"Bob-Open ",
    font:"Bob-Open"},
  {
    name:"BRAM ",
    font:"BRAM"},
  {
    name:"Caps ",
    font:"Caps"},
  {
    name:"GOLDMAN ",
    font:"GOLDMAN"},
  {
    name:"karashi-latino ",
    font:"karashi-latino"},
  {
    name:"PARM ",
    font:"PARM"},
  {
    name:"PROST ",
    font:"PROST"},
  {
    name:"Tone-Regular ",
    font:"Tone-Regular"},
  {
    name:"Toriland ",
    font:"Toriland"},
  {
    name:"TRON ",
    font:"TRON"},
  {
    name:"VLAD ",
    font:"VLAD"},
  {
    name:"Zelda ",
    font:"Zelda"},





  {
    name:"Droid Sans",
  font:"Droid Sans"},
  {
    name:"Chilanka",
  font:"Chilanka"},
  {
    name:"Consolas",
  font:"Consolas"},
  {
    name:"Calibri Light",
  font:"Calibri Light"},
  {
    name:"Brush Script MT",
  font:"Brush Script MT"},
  {
    name:"Bahnschrift SemiBold",
  font:"Bahnschrift SemiBold"},
  {
    name:" Arial Black",
  font:" Arial Black"},
  {
    name:"Jazz LET",
  font:"Jazz LET"},
  {
    name:"Impact",
  font:"Impact"},
  {
    name:"Bradley Hand",
  font:"Bradley Hand"},
  {
    name:" papyrus",
  font:" papyrus"},
  {
    name:"Lucida Console",
  font:"Lucida Console"},
  {
    name:"Courier New",
  font:"Courier New"},
  {
    name:"Monospace Fonts",
  font:"Monospace Fonts"},
  {
    name:"Verdana",
  font:"Verdana"},
  {
    name:"Trebuchet MS",
  font:"Trebuchet MS"},
  {
    name:"Tahoma",
  font:"Tahoma"},
  {
    name:"Comic Sans MS",
  font:"Comic Sans MS"},
  {
    name:"Arial Black",
  font:"Arial Black"},
  {
    name:"Arial",
  font:"Arial"},
  {
    name:"Palatino Linotype",
  font:"Palatino Linotype"},
  {
    name:"Georgia",
  font:"Georgia"},
  {
    name:"Zahey",
  font:"Zahey"},
]
function SubCanvasHandlers({values}) {

const {canvasData,setCanvasData,controls,setControls,fonts1,font}=values
    
  const [value, setValue] = useState(10);
  const [value1, setValue1] = useState(0.5);
  const [fontValue, setFontValue] = useState(14);
  const{canvas}=useSelector(state=>state.auth)

  const {
    addFourPointedStar,
    addStar,
    initAligningGuidelines,
    initCenteringGuidelines,
    initCanvasHandlers,
    addCurvedText,
    imageUpload,
    LineSpace,
    addSticker,
    addArrow,
    addLine,
    remove,
    fontItalic,
    fontBold,
    addRectangle,
    addCircle,
    addTriangle,
    addNewText,
    fontFamily,
    fontSize,
    fillColor,
    setStroke,
    TextCenter,
    LineHeight,
    ObjCenter,
    ObjRotate,
    ZoomOut,
    ZoomIn,
    CopyObj,
    setBackgroundImage,
    sendBack,
    bringFront,
    TextLeft,
    TextRight,
    fontUnderline
  } = handlers();

  const [prevFont,setPrevFont]=useState()
  const [clicked,setClicked]=useState(false)
  
  function checkFontFamily(edit,font){
  
  if(!clicked){if(edit){
  
    let preFont=canvas.getActiveObject()?.fontFamily
    setPrevFont(preFont)
      canvas.getActiveObject()?.set("fontFamily", font);
       canvas.requestRenderAll();
  }else if(prevFont){
    canvas.getActiveObject()?.set("fontFamily", prevFont);
    canvas.requestRenderAll();
    setPrevFont(null)
  }}
  }
  function changeFontFamily(font){
 
     
        canvas.getActiveObject()?.set("fontFamily", font);
         canvas.requestRenderAll();
  setClicked(true)
    
  
      }
 

  return (
    <>
    
{(controls.obj||controls.text||controls.sticker)&&    <div className="  px-3   py-2 bg-white " style={{borderTop:"0.5px solid rgb(213 194 194)"}}  >
    <div className=" d-flex justify-content-center  gap-4 flex-wrap align-items-center " >
      <div className='d-flex align-items-center flex-wrap gap-2'>
     
   {controls.text&&<>
    <div className="">
       
            <div class=" dropdown" onFocus={()=>setClicked(false)} style={{zIndex:'1'}}>
                      <button class="dropdown-toggle" type="button" id="dropdownMenuButton1" style={{border:"none ",borderRadius:"5px",width:"200px",height:"25px",display:"flex",justifyContent:"space-around",alignItems:"center"}} data-bs-toggle="dropdown" aria-expanded="false">
                      { font.fontFamily||"Select Font"}
                      </button>
                      <ul class="dropdown-menu" style={{maxHeight:"50vh",overflowY:"auto"}} aria-labelledby="dropdownMenuButton1"  >
                        {fonts1?.length>0&&<div className='px-3' >
                        <p className='mb-0'>Used Font in desgin</p>
                        {fonts1?.map(item=>( 
                        <>
                      <li style={{cursor:"pointer"}} ><a 
                      onClick={()=>changeFontFamily(item)}
                      onMouseEnter={()=>checkFontFamily(true,item)}  
                      class="dropdown-item"  style={{ fontFamily:item}} 
                      onMouseLeave={()=>checkFontFamily(false,item)} >{item}</a>
                      </li>
                        </>
                        ))
                        }
                        
                         
                        </div>}
                        <hr/>
                        <p className='px-1 py-3 mb-1 fw-bold' style={{backgroundColor:"#DCDCDC"}}>Arabic Fonts</p>

                        {fonts.map(item=>{

                         
                          return(
                            <>
                      <li style={{cursor:"pointer"}} ><a 
                      onClick={()=>changeFontFamily(item.font)}
                      onMouseEnter={()=>checkFontFamily(true,item.font)}  
                      class="dropdown-item"  style={{ fontFamily:item.font}} 
                      onMouseLeave={()=>checkFontFamily(false,item.font)} >{item.name}</a>
                      </li>
                      {item.font=="Dahka"&&<p className='px-1 py-3 mb-1 fw-bold' style={{backgroundColor:"#DCDCDC"}}>English and West European</p>}
                      </>
                        )
                        })}

                      </ul>
                      </div>            
      
        </div>
        <div className=''>
          {/* <div>
            <label className="mb-1 " htmlFor="font-si">
              Font Size
            </label>
          </div> */}
          <div className="bg_sky  d-flex align-items-center " style={{border:"0.5px solid black",borderRadius:"0",border:"none"}}>
           
          <IconButton   style={{height:"22px",width:"22px"}} aria-label="add" onClick={(e)=>{e.stopPropagation()
                if(fontValue==12) return
                setFontValue(fontValue-1)
                 fontSize(fontValue)
                 }}>
            <RemoveIcon />
          </IconButton>
         
            < input   className=" text-center  bg_sky"   type="number" min={12} max={72} name="fontSize" value={fontValue} onChange={(e)=>{
              setFontValue(e.target.value)
              fontSize(Number(e.target.value))
            }}
               list="fontSize" title='Font Size'  style={{ height:"22px",width:"45px"}}/>
               <div>
            <IconButton  style={{height:"22px",width:"22px"}} aria-label="add" onClick={(e)=>{e.stopPropagation()
                 if(fontValue==72) return
                 setFontValue(Number(fontValue)+1)
                 fontSize(Number(fontValue))
                 }}>
              <AddIcon />
            </IconButton>
            </div>
            <datalist id="fontSize">
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
            </datalist> 
     
          </div>
        </div>
        </>}
       {(controls.text||controls.sticker)&& <div class=" dropdown" style={{zIndex:'1'}}>
          <button 
            class=" "
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{backgroundColor:"transparent", border:"0"}}
          >
            <img src={require("../../assets/images/color-picker.png")} alt="" style={{width:"26px", height:'26px'}} />
           
          </button>
          <ul class="dropdown-menu ">
              <ColorPaletteFill/>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li className="p-2">
              <p className="mb-0 fw-bold"> <i> Pick a Custom Color</i></p>
              <div className='mt-2' style={{ position: "relative", display: "inline-block", }}>
                  <input
                    type="color"
                    id="fill"
                    onChange={(e) => fillColor(e.target.value)}
                    style={{
                      width: "32px",
                      height: "32px",
                      position: "relative",
                      top: "-5px",
                      left: "-5px",
                      zIndex: "1"
                    }}
                    value="#ADD8E6"
                  />
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "32px",
                    height: "32px",
                    backgroundImage: "linear-gradient(to right, #ff0000, #00ff00, #0000ff)",
                    opacity: "0.5",
                    zIndex: "0"
                  }}
                ></div>
            </div>

            </li>
          </ul>
        </div>}

     
      </div>
      
       <div className=" d-flex align-items-center flex-wrap gap-5 ">
        {controls.text&&  <div className=' d-flex gap-1 align-items-center'>
          <div className="mt-2">
            <input
              type="checkbox"
              id="bold"
              defaultValue={1}
              hidden
            />
            <label
              htmlFor="bold"
              id="font-bold"
              onClick={fontUnderline}
              className="material-icons pointer "
              title="Text Underline"
              style={{ padding: ".3rem", fontSize: "1.2rem", }}
            >
              format_underlined

             {/* <i class="fw-bold bi bi-type-underline"></i> */}
            </label>
          </div>
          <div className="mt-2">
            <input
              type="checkbox"
              id="bold"
              defaultValue={1}
              hidden
            />
            <label
              htmlFor="bold"
              id="font-bold"
              onClick={fontBold}
              className="material-icons pointer "
              style={{ padding: ".3rem", fontSize: "1.2rem", }}
              title="Text Bold"
            >
              {" "}
              format_bold
            </label>
          </div>
          <div className="mt-2">
            <input
              type="checkbox"
              id="italic"
              defaultValue={1}
              hidden
            />
            <label
              htmlFor="italic"
              id="font-italic"
              onClick={fontItalic}
              className="material-icons pointer btn_canvas"
              style={{ padding: ".3rem", fontSize: "1.2rem", }}
              title="Text Italic"
            >
              format_italic{" "}
            </label>
          </div>
          <button
              style={{
                border: "none",
                background: "none",
                fontSize: "1.2rem",
              }}
              data-bs-toggle="dropdown"
              id="TextCenter"
              className="text-center p-2 "
              title="Text align"
            >
              <i className="bi bi-text-center " />
            </button> 
            <ul class="dropdown-menu " >
              <div style={{display:"flex"}}>
              <li>
              <button
              style={{
                border: "none",
                background: "none",
                fontSize: "1.2rem",
              }}
              onClick={TextCenter}
              id="TextCenter"
              className="text-center p-2 "
              title="Text center"
            >
              <i className="bi bi-text-center " />
            </button> 
              </li>
              <li>
              <button
                style={{
                  border: "none",
                  background: "none",
                  fontSize: "1.2rem",
                }}
                onClick={TextLeft}
                id="TextCenter"
                className="text-center p-2 "
                title="Text Left"
              >
              <i class="bi bi-justify-left"></i>
              </button>
              </li>
              <li>
              <button
                style={{
                  border: "none",
                  background: "none",
                  fontSize: "1.2rem",
                }}
                onClick={TextRight}
                id="TextCenter"
                className="text-center p-2 "
                title="Text Right"
              >
              <i class="bi bi-text-right"></i>
              </button>
              </li>
              </div>
            </ul>

            {/* <button
                style={{
                  border: "none",
                  background: "none",
                  fontSize: "1.2rem",
                }}
                onClick={TextLeft}
                id="TextCenter"
                className="text-center p-2 "
                title="Text Left"
              >
              <i class="bi bi-justify-left"></i>
              </button> */}
          <div className=" d-flex align-items-center gap-1 ">
                     {/* ============================== */}
{/* <div>
            <button
              className
              type="button"
              title='Curve Text'
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{ border: "none", background: "none" ,cursor: "pointer" }}
              onClick={()=>{
                setShow(false)
                setShow1(!show1)}}
            >
              <img
                src={require("../../assets/images/text-curve.png")}
                className="img-fluid h-w"
                alt=""
              />
             
            </button>
  </div> */}
{/* ====================================== */}
            <button
              style={{ border: "none", background: "none" }}
              // onClick={LineHeight}
              data-bs-toggle="dropdown"
              id="LineHeight"
              className="text-center p-2"
              title="Line Space"
            >
              <img
                src={require("../../assets/images/left.png")}
                className="img-fluid d-flex my-auto"
                alt=""
                style={{ maxWidth: "15px" }}
              />
            </button>
            <ul class="dropdown-menu ">
              
              <li className='' style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
              <IconButton aria-label="add" onClick={(e)=>{e.stopPropagation()
                 setValue1(value1-0.1) 
                 LineHeight(value1)
                 }} >
            <RemoveIcon />
            </IconButton>
              <Slider  value={value1} step={0.1} min={1} max={4} onChange={(e)=>{e.stopPropagation()
              setValue1(e.target.value)
              LineHeight(value1)}} sx={{width:"50%"}} aria-label="Default" valueLabelDisplay="auto" />


              <IconButton aria-label="add" onClick={(e)=>{e.stopPropagation()
                 setValue1(value1+0.1)
                 LineHeight(value1)
                 }}>
<AddIcon />
</IconButton>
              </li>
             
            </ul>
            <button
              style={{ border: "none", background: "none" }}
              // onClick={LineSpace}
              data-bs-toggle="dropdown"
              id="LineHeight"
              className="text-center p-2"
              title="Character Spacing"
            >
              <img
                 src={require("../../assets/images/Aalingment.png")}
                className="img-fluid d-flex my-auto"
                alt=""
                style={{ maxWidth: "15px" }}
              />
            </button>
            <ul class="dropdown-menu ">
              
              <li className='' style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
              <IconButton aria-label="add" onClick={(e)=>{e.stopPropagation()
                 setValue(value-5) 
                 LineSpace(value)
                 }} >
            <RemoveIcon />
            </IconButton>
              <Slider  value={value} onChange={(e)=>{e.stopPropagation()
              setValue(e.target.value)
                LineSpace(value)}} sx={{width:"50%"}} aria-label="Default" valueLabelDisplay="auto" />


              <IconButton aria-label="add" onClick={(e)=>{e.stopPropagation()
                 setValue(value+5)
                 LineSpace(value)
                 }}>
              <AddIcon />
            </IconButton>
                </li>
             
            </ul>
       
          </div>
          </div>}
       {/* {(controls.sticker||controls.obj||controls.text)&&   <div className=' d-flex gap-1 align-items-center'>
           
         
           
            </div>} */}
        <div className='d-flex align-items-center justify-content-start gap-3'>
{(controls.sticker||controls.obj||controls.text)&&      <>

  <button style={{
                border: "none",
                background: "none",
                fontSize: "1.2rem",
              }}
              onClick={ObjRotate}
              className="text-center p-2"
              title="Object rotate"
              id="ObjRotate"
            >
              <i class="fw-bold bi bi-arrow-repeat"></i>
            </button>
           
      <button
          style={{ border: "none", background: "none" ,
              fontSize: "1.2rem",}}
              onClick={ObjCenter}
              id="ObjCenter"
              className="text-center"
              title="Object Center"
            >
              {/* <img
                src={require("../../assets/images/Aalingment.png")}
                className="img-fluid d-flex my-auto"
                alt=""
                style={{ maxWidth: "15px" }}
              /> */}
              <VerticalAlignCenterIcon/>
            </button>
        
        <div id="copy"
          className=" rounded-2"
          style={{}}
          data-toggle="tooltip"
          data-placement="top"
          title="Make copy"
        >
          {/* <img  src={require("../../assets/images/add.png")} className="img-fluid" alt="" /> */}
          <i
            onClick={CopyObj}
            class="bi bi-plus-square"
            style={{ fontSize: "1rem" }}
          ></i>
        </div>
        <button onClick={remove}
          id="remove"
          className="fw-bold"
          style={{ background: "none", border: "none" }}
          data-toggle="tooltip"
          data-placement="top"
          title="Remove "
        >
          <span
            className="material-icons"
            style={{ fontSize: "1.2rem" }}
          >
            {" "}
            delete{" "}
          </span>
        </button>
        <div onClick={sendBack}
          id="backward"
          className=" rounded-2"
          data-toggle="tooltip"
          data-placement="top"
          title="Send backward"
        >
          <i
            className="bi bi-layer-backward"
            style={{ fontSize: "1rem" }}
          />
        </div>
        <div onClick={bringFront}
            Custom Color id="front"
              className=" rounded-2"
              data-toggle="tooltip"
              data-placement="top"
              title="Bring to Front"
            >
              <i
                className="bi bi-front"
                style={{ fontSize: "1.2rem" }}
              />
          </div>
        </>}
        </div>
        </div>
    </div>
  </div>}
  </>
  )
}

export default SubCanvasHandlers