import { Avatar, Button, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import account from '../_mock/account'
import apiClient from '../../../api/apiClient';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
function Profile() {
  // {
  //   firstame: 'ali',
  //   lastName: 'hassan',
  //   email: '!@example.cpm',
  //   phoneNumber: '111-111-111',
  //   address: 'eandom address',
  //   password: '12345678',
  //   image: account.photoURL,
  // }
  const [formData, setFormData] = useState();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData();
    form.append('firstName', formData.firstName);
    form.append('lastName', formData.lastName);
    form.append('email', formData.email);
    form.append('phoneNumber', formData.phoneNumber);
    form.append('address', formData.address);
    form.append('image', formData.image);
console.log(formData)
    // Use the 'form' object to send data to the server or perform any other required actions
  };

 

  const fetchUser=async()=>{
    const result =await apiClient.get("/auth/user-info/")
    if(!result.ok) return console.log("error")
    console.log(result,"dsfsdfsdfsdf")
    setFormData(result?.data)
  }
  
  useEffect(()=>{
    fetchUser()
  },[])

  return (
     <form onSubmit={handleSubmit}>
{formData?

      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
          <Avatar
            alt="Remy Sharp"
            src={formData.image}
            sx={{ width: 206, height: 206 }}
          />
          <input type="file" onChange={handleImageChange} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="first-name-input"
            fullWidth
            label="First Name"
            variant="outlined"
            name="firstName"
            value={formData.name}
            onChange={handleInputChange}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            id="last-name-input"
            fullWidth
            label="Last Name"
            variant="outlined"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <TextField
            id="email-input"
            fullWidth
            label="Email"
            variant="outlined"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="phone-number-input"
            fullWidth
            label="Phone Number"
            variant="outlined"
            name="contact"
            value={formData.contact}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="address-input"
            fullWidth
            label="Address"
            variant="outlined"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="address-input"
            fullWidth
            type='password'
            label="Change Password"
            variant="outlined"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
          />
        </Grid>
       
        <Grid item xs={12} >
          <Button type="submit" fullWidth variant="contained">Submit</Button>
        </Grid>
      </Grid>:<Box sx={{ display: 'flex' ,justifyContent:"center"}}>
      <CircularProgress />
    </Box>}
    </form>
  );
}

export default Profile;
