import { Avatar, Button, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import account from '../_mock/account'
import apiClient from '../../../api/apiClient';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
function Setting() {
  // {
  //   firstame: 'ali',
  //   lastName: 'hassan',
  //   email: '!@example.cpm',
  //   phoneNumber: '111-111-111',
  //   address: 'eandom address',
  //   password: '12345678',
  //   image: account.photoURL,
  // }
  const [formData, setFormData] = useState({

    phone:"",
    whatsapp_no:"",
    email:"",
    facebook:"",
    instagram:"",
    website:"",
    address:""
  

  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };



  const handleSubmit = async(event) => {
    event.preventDefault();
const result=await apiClient.put("/setting",formData)
if(!result.ok) return

  };

 

  const fetchData=async()=>{
    const result =await apiClient.get("/setting/")
    if(!result.ok) return console.log("error")
    console.log(result,"dsfsdfsdfsdf")
    setFormData(result?.data.result)
  }
  
  useEffect(()=>{
    fetchData()
  },[])

  return (
     <form onSubmit={handleSubmit}>
{formData?

      <Grid container spacing={2}>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Phone Number"
            variant="outlined"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="phone-number-input"
            fullWidth
            label="Whatsapp Number"
            variant="outlined"
            name="whatsapp_no"
            value={formData.whatsapp_no}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="email-input"
            fullWidth
            label="Email"
            variant="outlined"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="address-input"
            fullWidth
            label="Address"
            variant="outlined"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Facebook"
            variant="outlined"
            name="facebook"
            value={formData.facebook}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Instagram"
            variant="outlined"
            name="instagram"
            value={formData.instagram}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Website Url"
            variant="outlined"
            name="website"
            value={formData.website}
            onChange={handleInputChange}
          />
        </Grid>
  
       
        <Grid item xs={12} >
          <Button type="submit" fullWidth variant="contained">Submit</Button>
        </Grid>
      </Grid>:<Box sx={{ display: 'flex' ,justifyContent:"center"}}>
      <CircularProgress />
    </Box>}
    </form>
  );
}

export default Setting;
