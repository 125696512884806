import PropTypes from 'prop-types';
// @mui
import { Box, Card,  Typography, Stack, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
// ----------------------------------------------------------------------
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import apiClient from '../../../../../api/apiClient';
import { Edit } from '@mui/icons-material';
const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product ,fetchProduct}) {
  const { name,product_image,_id, cover, price, colors, status, priceSale } = product;
  const [load, setLoad] = useState(false);
  const navigate=useNavigate()
const handleDelete=async(id)=>{
  setLoad(true)
  const result =await apiClient.delete(`/product/${id}`)
  setLoad(false)
  if(!result.ok) return console.log("error")
  fetchProduct()
  
}
  return (
    <Card  style={{cursor:'pointer'}}>
      <Link to='/singleproduct' state={product} style={{textDecoration:"none",color:"inherit"}} >
   
     
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )}
        <StyledProductImg alt={name} src={product_image} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {/* <ColorPreview colors={colors} /> */}
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through',
              }}
            >
              {priceSale && fCurrency(priceSale)}
            </Typography>
            &nbsp;
            {fCurrency(price)}
          </Typography>
        </Stack>
      </Stack>
      </Link>
      <IconButton sx={{ position: 'absolute', top: '10px', right: '10px',zIndex:1000 }}
         
         aria-label="add to favorites">
           <DeleteIcon  onClick={(e)=>{
           e.stopPropagation()
           handleDelete(_id)         }}/>
         </IconButton>
         <IconButton sx={{ position: 'absolute', top: '10px', left: '10px',zIndex:1000 }}
         
         aria-label="add to favorites">
           <Edit  onClick={(e)=>{
           e.stopPropagation()
         navigate(`/admin/products/${_id}`)     }}/>
         </IconButton>
    </Card>
   
  );
}
