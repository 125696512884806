import { async } from 'q'
import React, { useRef, useState } from 'react'
import apiClient from '../api/apiClient'
import { Toast } from 'primereact/toast';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router';
export default  () => {
    let initialState={
        name:'',
        contact:"",
        email:"",
        password:"",
    }
    const navigate=useNavigate()
    const[loading,setLoading]=useState(false)
    const[register,setRegister]=useState(initialState)
    const handleChange=(key,value)=>{
   setRegister({...register,[key]:value})
    }
    const toast = useRef(null)
    const showError = () => {
  
      toast.current.show({severity:'error', summary: 'Error', detail:'Error', life: 3000});
  }
    const handleSubmit=async(e)=>{
        e.preventDefault()
        setLoading(true)
        const result =await apiClient.post("/auth/register",register) 
        setLoading(false)
    if(!result.ok) return showError()
    navigate("/login")
        console.log(result,"dfsfsfsd")
        console.log(register)
    }
  return (
    <div>
        <form onSubmit={handleSubmit} className='d-flex justify-content-center p-3'>
         <div className=''>
         <Toast ref={toast} />
        <div class="mb-3">
            <label for="fname" class="form-label">Name</label>
            <input type="text" value={register.name} onChange={(e)=>handleChange("name",e.target.value)} class="form-control" id="fname" aria-describedby="emailHelp" required/>
        
        </div>
        <div class="mb-3">
            <label for="lastname" class="form-label">Contact</label>
            <input  type="number" value={register.contact} onChange={(e)=>handleChange("contact",e.target.value)} class="form-control" id="lastname" aria-describedby="emailHelp" required/>
        
        </div>
        <div class="mb-3">
            <label for="email" class="form-label">Email address</label>
            <input type="email" class="form-control" value={register.email} onChange={(e)=>handleChange("email",e.target.value)} id="email" aria-describedby="emailHelp" required/>
            <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
        </div>
        <div class="mb-3">
            <label for="pass" class="form-label">Password</label>
            <input type="password" class="form-control" value={register.password} onChange={(e)=>handleChange("password",e.target.value)} id="pass" required/>
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
            </div>
        </form>

    </div>
  )
}
