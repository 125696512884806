import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import {persistor, store} from './redux/store'
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from './component/Admin/theme';
import ScrollToTop from './component/Admin/components/scroll-to-top/ScrollToTop';
import { StyledChart } from './component/Admin/components/chart';
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import 'primeicons/primeicons.css';
//core
import "primereact/resources/primereact.min.css";     

// Bootstrap Bundle icon
import "bootstrap-icons/font/bootstrap-icons.css";
// Bootstrap Bundle css
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";       
import { PersistGate } from 'redux-persist/integration/react';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
    <HelmetProvider>
    <BrowserRouter>
   

      <ThemeProvider>
        <ScrollToTop />
        <StyledChart />
    <App />
    </ThemeProvider>
    </BrowserRouter>
    </HelmetProvider>
    </PersistGate>
    </Provider>
);

