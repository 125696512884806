import React, { useState, useEffect, useContext } from "react";
import { fabric } from "fabric";
import "fabric-history";
import handlers from "../../modules/canvasHandlers";
import apiClient from "../../api/apiClient";
import { toast } from "react-toastify";
import {Popup} from "../mui";
const fonts = [
  {
    name: "Fenoon  تحيات",
    font: "Fenoon",
  },
  {
    name: "Wafir  تحيات",
    font: "Wafir",
  },
  {
    name: "Tamema  تحيات",
    font: "Tamema",
  },
  {
    name: "Tamema Embossed  تحيات",
    font: "Tamema Embossed",
  },
  {
    name: "Talasem  تحيات",
    font: "Talasem",
  },
  {
    name: "Shafrah  تحيات",
    font: "Shafrah",
  },
  {
    name: "Rakan  تحيات",
    font: "Rakan",
  },
  {
    name: "Nishan  تحيات",
    font: "Nishan",
  },
  {
    name: "Naghamah  تحيات",
    font: "Naghamah",
  },
  {
    name: "Maharah  تحيات",
    font: "Maharah",
  },
  {
    name: "Lamhah  تحيات",
    font: "Lamhah",
  },
  {
    name: "Laftah  تحيات",
    font: "Laftah",
  },
  {
    name: "Labaqah  تحيات",
    font: "Labaqah",
  },
  {
    name: "Kufigraph  تحيات",
    font: "Kufigraph",
  },
  {
    name: "Kaleem  تحيات",
    font: "Kaleem",
  },
  {
    name: "Kaleel  تحيات",
    font: "Kaleel",
  },
  {
    name: "Jazeel  تحيات",
    font: "Jazeel",
  },
  {
    name: "Ithra  تحيات",
    font: "Ithra",
  },
  {
    name: "Inseyab  تحيات",
    font: "Inseyab",
  },
  {
    name: "Origami Notepad  تحيات",
    font: "Origami Notepad",
  },
  {
    name: "Ahlan  تحيات",
    font: "Ahlan",
  },
  {
    name: "Atyaaf  تحيات",
    font: "Atyaaf",
  },
  {
    name: "Bareeq  تحيات",
    font: "Bareeq",
  },
  {
    name: "Zelda-Regular  تحيات",
    font: "Zelda-Regular",
  },
  {
    name: "Etlalah  تحيات",
    font: "Etlalah",
  },
  {
    name: "Tashkeel-Monochrome  تحيات",
    font: "Tashkeel-Monochrome",
  },
  {
    name: "Dahka  تحيات",
    font: "Dahka",
  },

  {
    name: "Adine ",
    font: "Adine",
  },
  {
    name: "Almeda-Regular ",
    font: "Almeda-Regular",
  },
  {
    name: "BS-Regular ",
    font: "BS-Regular",
  },
  {
    name: "AEOLUS ",
    font: "AEOLUS",
  },
  {
    name: "AGENCYB ",
    font: "AGENCYB",
  },
  {
    name: "AGENCYR ",
    font: "AGENCYR",
  },
  {
    name: "AguafinaScript ",
    font: "AguafinaScript",
  },
  {
    name: "Akka ",
    font: "Akka",
  },
  {
    name: "Akronim ",
    font: "Akronim",
  },
  {
    name: "AlexBrush ",
    font: "AlexBrush",
  },
  {
    name: "Allura ",
    font: "Allura",
  },
  {
    name: "Almo ",
    font: "Almo",
  },
  {
    name: "AmazOOSTROVFine ",
    font: "AmazOOSTROVFine",
  },
  {
    name: "Amita ",
    font: "Amita",
  },
  {
    name: "AMRTEXTN ",
    font: "AMRTEXTN",
  },
  {
    name: "AnnieUseYourTelescope ",
    font: "AnnieUseYourTelescope",
  },
  {
    name: "Arizonia ",
    font: "Arizonia",
  },
  {
    name: "BankScrd ",
    font: "BankScrd",
  },
  {
    name: "Ballet ",
    font: "Ballet",
  },
  {
    name: "BARR ",
    font: "BARR",
  },
  {
    name: "Bob-Open ",
    font: "Bob-Open",
  },
  {
    name: "BRAM ",
    font: "BRAM",
  },
  {
    name: "Caps ",
    font: "Caps",
  },
  {
    name: "GOLDMAN ",
    font: "GOLDMAN",
  },
  {
    name: "karashi-latino ",
    font: "karashi-latino",
  },
  {
    name: "PARM ",
    font: "PARM",
  },
  {
    name: "PROST ",
    font: "PROST",
  },
  {
    name: "Tone-Regular ",
    font: "Tone-Regular",
  },
  {
    name: "Toriland ",
    font: "Toriland",
  },
  {
    name: "TRON ",
    font: "TRON",
  },
  {
    name: "VLAD ",
    font: "VLAD",
  },
  {
    name: "Zelda ",
    font: "Zelda",
  },

  {
    name: "Droid Sans",
    font: "Droid Sans",
  },
  {
    name: "Chilanka",
    font: "Chilanka",
  },
  {
    name: "Consolas",
    font: "Consolas",
  },
  {
    name: "Calibri Light",
    font: "Calibri Light",
  },
  {
    name: "Brush Script MT",
    font: "Brush Script MT",
  },
  {
    name: "Bahnschrift SemiBold",
    font: "Bahnschrift SemiBold",
  },
  {
    name: " Arial Black",
    font: " Arial Black",
  },
  {
    name: "Jazz LET",
    font: "Jazz LET",
  },
  {
    name: "Impact",
    font: "Impact",
  },
  {
    name: "Bradley Hand",
    font: "Bradley Hand",
  },
  {
    name: " papyrus",
    font: " papyrus",
  },
  {
    name: "Lucida Console",
    font: "Lucida Console",
  },
  {
    name: "Courier New",
    font: "Courier New",
  },
  {
    name: "Monospace Fonts",
    font: "Monospace Fonts",
  },
  {
    name: "Verdana",
    font: "Verdana",
  },
  {
    name: "Trebuchet MS",
    font: "Trebuchet MS",
  },
  {
    name: "Tahoma",
    font: "Tahoma",
  },
  {
    name: "Comic Sans MS",
    font: "Comic Sans MS",
  },
  {
    name: "Arial Black",
    font: "Arial Black",
  },
  {
    name: "Arial",
    font: "Arial",
  },
  {
    name: "Palatino Linotype",
    font: "Palatino Linotype",
  },
  {
    name: "Georgia",
    font: "Georgia",
  },
  {
    name: "Zahey",
    font: "Zahey",
  },
];
function MainCanvasHandlers({admin}) {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const {
    addFourPointedStar,
    addStar,
    imageUpload,
    LineSpace,
    addSticker,
    addArrow,
    addLine,
    addNewText,
    setBackgroundImage,
  } = handlers();

  return (
    <>
    

    <div className="d-flex justify-content-center align-items-center gap-4">
      <div>
        <button
          className="bg-white"
          type="button"
          aria-controls="collapseExample"
          style={{ border: "none", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();

            setShow(!show);
          }}
        >
          <img
            src={require("../../assets/images/AddSticker.png")}
            className="img-fluid h-w"
            alt=""
          />{" "}
          <label className="white_space">Add Shapes</label>
        </button>
      </div>
      <div>
        <input onChange={imageUpload} hidden type="file" id="upload" />
        <label htmlFor="upload">
          <img
            src={require("../../assets/images/AddImage.png")}
            className="img-fluid h-w"
            alt=""
          />
          <span className="ps-1">Add Image</span>
        </label>
      </div>
      <div id="newText" onClick={addNewText}>
        <label>
          <img
            src={require("../../assets/images/AddText.png")}
            className="img-fluid h-w"
            alt=""
          />
          <label className="white_space">Add Text</label>
        </label>
      </div>

    {admin&&  <div className="btn-group" onClick={setBackgroundImage}>
        <label>
          <label className="white_space">Fix Background</label>
        </label>
      </div>}
    </div>
    <Popup
open={show}
onClose={()=>setShow(false)}
    content={  <div className="card card-body ">
    <div className="images_1 d-flex gap-2 flex-wrap">
    <div onClick={(e)=>{
      setShow(false)
      addFourPointedStar(e)}} title="Four side star">
      <img style={{width: '2rem', height:"auto"}} src={require("../../assets/four-side.png")} className="img-fluid h-w" alt="" />
      </div>
    <div onClick={(e)=>{
      setShow(false)
      addStar(e)}} title="six side star">
    <img style={{width: '2rem', height:"auto"}} src={require("../../assets/six-side.png")} className="img-fluid h-w" alt="" />
    </div>
    <div class="" onClick={
      (e)=>{
        setShow(false)
        addSticker(e)}}  > 
    <img style={{width: '2rem', height:"2rem"}} src={require("../../assets/icons/circle.svg").default} className="img-fluid h-w" alt="" />
    </div>
    <div class="" onClick={
      (e)=>{
        setShow(false)
        addSticker(e)}}  > 
    <img style={{width: '2rem', height:"2rem"}} src={require("../../assets/icons/triangle.svg").default} className="img-fluid h-w" alt="" />
    </div>
    <div  class="" onClick={
      (e)=>{
        setShow(false)
        addSticker(e)}} > 
    <img style={{width: '2rem', height:"2rem"}} src={require("../../assets/icons/square.svg").default} className="img-fluid h-w" alt="" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addSticker(e)}} src={require("../../assets/icons/heptagon.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addSticker(e)}} src={require("../../assets/icons/pentagon.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addSticker(e)}} src={require("../../assets/icons/star.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addSticker(e)}} src={require("../../assets/icons/suit-heart.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addSticker(e)}} src={require("../../assets/icons/cloud-drizzle.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addSticker(e)}} src={require("../../assets/icons/lightning.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addSticker(e)}} src={require("../../assets/icons/diamond.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addArrow(e)}} src={require("../../assets/icons/arrow.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addArrow(e)}} src={require("../../assets/icons/star1.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addArrow(e)}} src={require("../../assets/icons/rightarrow.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addArrow(e)}} src={require("../../assets/icons/la.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addArrow(e)}} src={require("../../assets/icons/up.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addSticker(e)}} src={require("../../assets/icons/chat-right.svg").default} className="image" />
    </div>
    <div style={{width: '4rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addLine(e)}} src={require("../../assets/icons/horizontal-line.svg").default} className="image" />
    </div>
    {/* +++++++++++++++++++++add shapess++++++++++++++++++++++++++++++++++++++++ */}
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addLine(e)}} src={require("../../assets/icons/01.svg").default} className="image" />
    </div>
    
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addLine(e)}} src={require("../../assets/icons/12.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addLine(e)}} src={require("../../assets/icons/13.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addLine(e)}} src={require("../../assets/icons/14.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addLine(e)}} src={require("../../assets/icons/15.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addLine(e)}} src={require("../../assets/icons/16.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addLine(e)}} src={require("../../assets/icons/17.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addLine(e)}} src={require("../../assets/icons/18.svg").default} className="image" />
    </div>
    <div style={{width: '2rem'}}>
        <img width="100%" onClick={
      (e)=>{
        setShow(false)
        addLine(e)}} src={require("../../assets/icons/19.svg").default} className="image" />
    </div>
    
    
    </div>
</div>}
    />



    </>
  );
}

export default MainCanvasHandlers;
