import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import p2 from "./../assets/p2.jpg"
import p1 from "./../assets/2.jpg"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProductCard from '../component/ProductCard'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addToCart } from '../redux/CartSlice'
import PRODUCTS from '../component/Admin/_mock/products';
import { Galleria } from 'primereact/galleria';
import { Image } from 'primereact/image';
import apiClient from '../api/apiClient'
import ContactIcons from '../component/ContactIcons'
const SingleProduct = () => {
  const dispatch =useDispatch()
  const [selectedItem, setSelectedItem] = useState(null);
    console.log(selectedItem,"first")
    const location = useLocation();
    const data = location.state;
    console.log(data,"sdasdsadasdasdasdasdas")
    const [images, setImages] = useState(data?.other_images);
   
    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 3 
        }
    ];

    // useEffect(() => {
    //     PhotoService.getImages().then(data => setImages(data));
    // }, [])
console.log(images,"images")
    const itemTemplate = (item) => {
        return <Image className='img-fluid' src={item } alt={item.alt} style={{ width: '100%' }}  preview />
    }

    const thumbnailTemplate = (item) => {
      return (
        <img
          src={item}
          alt={item.alt}
          className='img-fluid'
          style={{ objectFit: 'contain', maxHeight: '50px', width: '100%' }}
        />
      );
    };
    
    
  
    const[products,setProducts]=useState()

    const fetchProduct=async()=>{
      const result =await apiClient.get("/product/")
      if(!result.ok) return console.log("error")
      console.log(result?.data.results,"dsfsdfsdfsdf")
      setProducts(result?.data.results)
    }
    useEffect(() => {
      fetchProduct()
    }, [])




    function addToCart(itemId, itemName, itemPrice,image,size) {
      // Check if the cart already exists in local storage
      let cart = JSON.parse(localStorage.getItem('cart')) || [];
    
      // Check if the item already exists in the cart
      const existingItem = cart.find(item => item.id === itemId);
    
      if (existingItem) {
        // If the item exists, update the quantity
        existingItem.quantity += 1;
      } else {
        // If the item doesn't exist, add it to the cart
        cart.push({ id: itemId, name: itemName, price: itemPrice,image:image,size:size, quantity: 1 });
      }
    
      // Update the cart in local storage
      localStorage.setItem('cart', JSON.stringify(cart));
    }
    




    const settingData=useSelector(state=>state.auth.settings)

  return (
    <>
    <Grid container className='p-2'>
        {/* product image  */}
       <Grid item xs={12} md={6}>
      
    <Galleria
      value={images}
      autoPlay={true}
      responsiveOptions={responsiveOptions}
      numVisible={5}
      style={{ width: '100%', height: '60%' }}
      item={itemTemplate}
      thumbnail={thumbnailTemplate}
    />
  
       </Grid>
       <Grid item xs={12} md={6}>
       <div style={{padding:"20px"}}>
        <h3 className='mb-o'>{data?.name}</h3>
        <p>{data?.description}</p>
        {!data?.contact_for_price&&<h5>${data?.price} </h5>}
{data?.on_demand?
<h6>Customized Size options available</h6>
:        <>
        <div className='d-flex ' style={{justifyContent:"space-between",alignItems:"center"}}>
        
        <h6>Size</h6>
        <h6>Size Guide</h6>
        </div>
        <div className=' d-flex gap-2'> 
        {data?.sizes?.map((item)=>(
        <div style={{border:`1px solid ${item === selectedItem ? "black" : "grey"}`, width:"50px", height:"50px", display:"flex",cursor:"pointer" ,justifyContent:"center", alignItems:"center"}}
        onClick={()=>setSelectedItem(item)}>
    <Box style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>
        <p style={{display: "flex", justifyContent: "center", alignItems: "center", margin: 0}}>{item}</p>
    </Box>
</div>))}
        
       
</div>
        </>}
<div style={{padding:'20px'}}>
{data?.contact_for_price?
       <div className='d-flex align-items-center gap-2'>

        <Typography variant="body2" color="text.secondary">
        Contact for price
        </Typography>
        <ContactIcons phone={settingData?.phone} whatsapp={settingData?.whatsapp_no} 
        // message={`Hi!Inquiring for this product:${window.location.origin}`}
        />
       </div>
       :  <>
       
       <Button variant='contained'sx={{backgroundColor:"#212B36"}} fullWidth onClick={()=>addToCart(data._id, data.name, data.price,data.product_image,selectedItem)}>Add to Cart</Button>
       <p className='pt-2 d-flex justify-content-center'>In 4 payments of £8.33 with  <span className='text-white px-1 ' style={{backgroundColor:"black",borderRadius:"20px"}}>Clear pay</span>  Learn More...<span>  <ContactIcons phone={settingData?.phone} whatsapp={settingData?.whatsapp_no} /></span></p>
     
       </> 
     }
  </div>
<Divider component="" />
<div className='mt-4'>
<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Description</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <p>
         Train and perform like a professional in your own Leicester Tigers 2022/23 Change Kit. A direct replica of the players kit, this shirt is made using Samurai's PerformeX™ technology and offers durability and breathability so you don't have to compromise. Complete with the silicone Tigers badge. 
         </p>
        </AccordionDetails>
      </Accordion>
<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Shipping & Returns</Typography>
        </AccordionSummary>
        <AccordionDetails>
         <p>
         Free UK Shipping over £60
<br/>
We ship worldwide!
<br/>
Hassle free returns
         </p>
        </AccordionDetails>
      </Accordion>
</div>
        </div>
       </Grid>
       {/* cart infooo   */}
       
    </Grid>
    <Grid container className='p-2' >
      
       <Grid item xs={12} className='d-flex justify-content-center'>
        <h1>You may also like</h1>
       </Grid>
       <Grid container spacing={3}>
       {products?.slice(0, 4).map((product)=>
        (
         
            <Grid item xs={6}  md={4} lg={3}>
                <Link to='/singleproduct' state={product} style={{textDecoration:"none",color:"inherit"}}>
            <ProductCard product={product} />
            </Link>
        </Grid>
        ))}
        </Grid>
        </Grid>
    </>
  )
}

export default SingleProduct