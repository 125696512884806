import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Home/Header/Header';
import Navbar from './Home/Navbar/Navbar';
import Footer from './Home/Footer/Footer';





export default function Layout() {

  return (
    <div>
        <Header />
        <Navbar />
<div>

        <Outlet />
</div>
<Footer/>
   
    </div>
  );
}
