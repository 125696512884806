import React from 'react';
import IconButton from '@mui/material/IconButton';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';



const ContactIcons = ({phone,whatsapp,message}) => {

  return (
    <div>
          <Link component="a" style={{ color: 'inherit', // Preserves the default text color
    textDecoration: 'inherit', }} to={`tel:${phone}`} onClick={(e)=>{
        e.stopPropagation();
    }}>
        <PhoneIcon  style={{fontSize:20}}/>
    </Link>
    

      {/* WhatsApp Icon with a link to open WhatsApp */}
      <Link component="a" style={{ color: 'inherit', // Preserves the default text color
    textDecoration: 'inherit', }} to={`https://wa.me/${whatsapp}?text=${message||""}`} onClick={(e)=>{
        e.stopPropagation();
    }}>

<WhatsAppIcon  style={{fontSize:20}} />
    </Link>
   
    </div>
  );
};


export default ContactIcons;
