import React from 'react'
import { Link } from 'react-router-dom'

function NavigateWrapper({to,external,children,...props}) {
    if(external)
    external=ensureHttps(external)
  return (
    <Link style={{ color: 'inherit', // Preserves the default text color
    textDecoration: 'inherit', }} to={to||external} onClick={(e)=>{
        e.stopPropagation();
    }} {...props}>
        {children}
    </Link>
  )
}

export default NavigateWrapper

function ensureHttps(url) {
    // Check if the URL starts with "http://" or does not start with any protocol
    if (!/^https?:\/\//i.test(url)) {
      // If not, add "https://"
      url = "https://" + url;
    }
    return url;
  }