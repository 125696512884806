import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./component/Home/Home";
import ProductDetails from "./component/ProductDetails/ProductDetails";
import Login from "./Page/Login";
import Register from "./Page/Register";
import Products from "./Page/Products";
import SingleProduct from "./Page/SingleProduct";
import WishList from "./Page/WishList";
import Cart from "./Page/Cart";
import DashboardAppPage from "./component/Admin/pages/DashboardAppPage";
import UserPage from "./component/Admin/pages/UserPage";
import ProductsPage from "./component/Admin/pages/ProductsPage";
import BlogPage from "./component/Admin/pages/BlogPage";
import Page404 from "./component/Admin/pages/Page404";
import Profile from "./component/Admin/pages/Profile";
import AddProduct from "./component/Admin/pages/AddProduct";
import AddCategory from "./component/Admin/pages/AddCategory";
import CreateMockup from "./Page/admin/CreateMockup";
import Blog from "./Page/Blog";
import DashboardLayout from "./component/Admin/layouts/dashboard/DashboardLayout";
import Layout from "./component/Layout";
import ProtectedRoute from "./component/ProtectedRoute";
import Setting from "./component/Admin/pages/Setting";
import { useDispatch } from "react-redux";
import { fetchSettings } from "./redux/CounterSlice";
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSettings()); 
  }, [dispatch]);

  return (


        <Routes>

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register/>} />

          <Route element={<Layout/>}>
          <Route index element={<Home />} />
          <Route path="product-details" element={<ProductDetails />} />
          <Route path="/register" element={<Register/>} />
          <Route path="/collection/:id" element={<Products/>} />
          <Route path="/collection/" element={<Products/>} />
          <Route path="/singleproduct" element={<SingleProduct/>} />
          <Route path="/wish-list" element={<WishList/>} />
          <Route path="/cart" element={<Cart/>} />
          </Route>
        
          <Route  element={<ProtectedRoute><DashboardLayout/></ProtectedRoute> } >
          <Route path="/admin/dashboard" element={ <DashboardAppPage/>} />
          <Route path="/admin/user" element={ <UserPage/>} />
          <Route path="/admin/products" element={ <ProductsPage/>} />
          <Route path="/admin/products/:id" element={ <AddProduct/>} />
          <Route path="/admin/blog" element={ <BlogPage/>} />
          <Route path="/admin/mockup/create" element={ <CreateMockup/>} />
          <Route path="/admin/profile" element={ <Profile/>} />
          <Route path="/admin/settings" element={ <Setting/>} />
          <Route path="/admin/new-product" element={ <AddProduct/>} />
          <Route path="/admin/new-category" element={ <AddCategory/>} />
          <Route path="/admin/blogs" element={ <Blog/>} />
          </Route>
          <Route path="/admin" element={ <Navigate to={"/admin/dashboard"}/>} replace />
          <Route path="*" element={ <Page404/>} replace />
        
        </Routes>

  );
}


export default App;
